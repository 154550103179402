import { ResponsiveContext } from "grommet";
import React from "react";
import styled from "styled-components";
interface Props {}

export const LeftColumn: React.FC<Props> = ({ children }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => {
        if (size === "small") {
          return <>{children}</>;
        }
        return <LeftColumnWrapper>{children}</LeftColumnWrapper>;
      }}
    </ResponsiveContext.Consumer>
  );
};

const LeftColumnWrapper = styled.div`
  margin-right: 10px;
  flex: 1;
  flex-grow: 1;
`;
