import { ResponsiveContext } from "grommet";
import _ from "lodash";
import moment from "moment";
import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Columns, LeftColumn, RightColumn } from "../components/layout/Columns";
import { ShadowBox } from "../components/layout/ShadowBox";
import { MeetingListItem } from "../components/MeetingListItem";
import { NewsListing } from "../components/NewsListing";
import { useMeetingsQuery } from "../generated/graphql";

export const HomePage: React.FC = () => {
  // if (!data) {
  //   return <div>Loading...</div>;
  // }

  // const { user } = useContext(UserContext);
  const { data: meetingData, loading } = useMeetingsQuery({
    variables: { limit: 5 }
  });
  // const [deleteMeeting] = useDeleteMeetingMutation({
  //   refetchQueries: ["Meetings"]
  // });

  if (loading) {
    return null;
  }

  // order by monts

  let groupedMeetingList;
  let groupedResults;
  if (
    meetingData &&
    typeof meetingData.meetings !== "undefined" &&
    meetingData.meetings.length
  ) {
    groupedResults = _.groupBy(meetingData.meetings, meeting => {
      console.log(meeting);
      console.log(new Date(meeting["date"]));
      return moment(new Date(meeting["date"]))
        .startOf("month")
        .toDate();
    });
    // const orderedGroupedResults = {};
    // Object.keys(groupedResults)
    //   .sort(function(a, b) {
    //     a = +new Date(a);
    //     b = +new Date(b);
    //     return a > b ? -1 : a < b ? 1 : 0;
    //   })
    //   .forEach(function(key) {
    //     orderedGroupedResults[key] = groupedResults[key];
    //   });
    // groupedMeetingList =
    const groupedAndSortedResults = Object.keys(groupedResults).sort(function(
      a,
      b
    ) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return +new Date(a) - +new Date(b);
    });
    // console.log("GROUPED: ", groupedAndSortedResults);
    groupedMeetingList = groupedAndSortedResults.map((o, idx) => {
      console.log(groupedResults[o]);
      return (
        <div
          key={`grouped-meeting-list-month-${idx}`}
          style={{
            marginTop: idx === 0 ? 0 : 50
          }}
        >
          <h3
            style={{
              textTransform: "capitalize",
              color: "#398722",
              fontWeight: 900
            }}
          >
            <Moment format="MMMM" locale="sv">
              {o}
            </Moment>
          </h3>
          {groupedResults[o].map((meeting, idx) => (
            <MeetingListItem
              key={`meetinglist-meeting-${idx}`}
              idx={idx}
              meeting={meeting}
            />
          ))}
        </div>
      );
    });
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        console.log(size);
        return (
          <>
            <h1>Välkommen</h1>
            <Columns>
              <LeftColumn>
                <h3>Nyheter</h3>
                <Link to="/news">Alla Nyheter</Link>
                <NewsListing limit={3} />
              </LeftColumn>
              <RightColumn>
                <h3>Kalender</h3>
                <Link to="/k">Alla Aktiviteter</Link>
                {!meetingData && !meetingData!.meetings && (
                  <div>Inga kalenderaktiviteter hittades</div>
                )}

                <ShadowBox>
                  {groupedMeetingList && groupedMeetingList}
                </ShadowBox>
                {/*  && groupedResults.map((g, idx) => <p>{g}</p>)} */}
              </RightColumn>
            </Columns>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};
