import { Collapsible } from "grommet";
import { Down, Trash, Up } from "grommet-icons";
import React, { useContext, useState } from "react";
import Moment from "react-moment";
import { UserContext } from "../contexts/UserContext";
import { useDeleteMeetingMutation } from "../generated/graphql";
import { FileRow } from "./lists/FileRow";

interface Props {
  meeting: any;
  idx?: number;
}

export const MeetingListItem: React.FC<Props> = ({ meeting, idx = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(UserContext);
  const [deleteMeeting] = useDeleteMeetingMutation({
    refetchQueries: ["Meetings"]
  });
  return (
    <div
      style={{
        marginBottom: 20,
        borderTop: idx > 0 ? "1px solid #ededed" : 0,
        paddingTop: idx > 0 ? 20 : 0
      }}
    >
      {/* RADEN */}
      <div
        style={{
          display: "flex"

          //   justifyContent: "space-between"
        }}
      >
        {/* DATUMET */}
        <div>
          <div
            style={{
              color: "#398722",
              fontSize: 18,
              fontWeight: "bold",
              border: "1px solid #398722",
              padding: 8,
              borderRadius: 100,
              marginRight: 10,
              marginTop: 8
            }}
          >
            <Moment format="DD">{meeting.date}</Moment>
          </div>
        </div>
        {/* INFO */}
        <div style={{ flexGrow: 1 }}>
          {user && user.isAdmin && (
            <div
              style={{
                float: "right"
              }}
              onClick={() => {
                if (
                  window.confirm(`Vill du verkligen ta bort ${meeting.title}?`)
                ) {
                  deleteMeeting({ variables: { id: meeting.id } });
                }
              }}
            >
              <Trash color="rgba(0,0,0,0.3)" />
            </div>
          )}
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            style={{ marginRight: "4px", float: "right" }}
          >
            {(meeting.content || meeting.url || meeting.files.length > 0) && (
              <>{isOpen ? <Up /> : <Down />}</>
            )}
          </div>
          <div>
            <strong>{meeting.title}</strong>
          </div>
          <div>
            <strong>Tid:</strong>{" "}
            <Moment format="HH:mm" locale="sv">
              {meeting.date}
            </Moment>
            {meeting.location && (
              <>
                {","} <strong>Plats</strong>: {meeting.location}
              </>
            )}
          </div>
          {(meeting.content || meeting.url || meeting.files.length > 0) && (
            <Collapsible open={isOpen} direction="vertical">
              {meeting.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: meeting.content.replace(/\\n/g, "")
                  }}
                />
              )}
              {meeting.url && (
                <div>
                  <div>
                    <strong>Bifogad länk</strong>
                  </div>
                  <a
                    href={meeting.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Öppna länk
                  </a>
                </div>
              )}
              {meeting.files.length > 0 && (
                <>
                  <div style={{ marginTop: meeting.url ? 20 : 0 }}>
                    <strong>Bifogade dokument</strong>
                  </div>
                  <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                    {meeting.files.map((file, idx) => {
                      console.log(file);
                      return (
                        <FileRow
                          key={`articles-files-list-file-${idx}`}
                          file={file}
                          folderId={meeting.id}
                        />
                      );
                    })}
                  </ul>
                </>
              )}
            </Collapsible>
          )}
        </div>
      </div>
    </div>
  );
};
