import React from "react";
import { compareByName } from "../../utils/compare";
import { Folder } from "./Folder";
interface Props {
  folders?: any;
  setCurrentFolderId: (s: string) => void;
  setShowFileModal: (s: boolean) => void;
  setShowEditFolderModal: (s: boolean) => void;
  currentFolderLevel: number;
  setCurrentFolderLevel: (n: number) => void;
}

export const FolderList: React.FC<Props> = ({
  folders = [],
  setCurrentFolderId,
  setShowFileModal,
  setShowEditFolderModal,
  currentFolderLevel,
  setCurrentFolderLevel
}) => {
  // useEffect(() => {
  //   setCurrentFolderLevel(currentFolderLevel + 1);
  // }, []);
  if (!folders.length) {
    return <>Inga mappar hittades</>;
  }
  console.log(folders);
  return (
    <ul
      style={{
        listStyle: "none",
        margin: 0,
        padding: 0
      }}
    >
      {folders.sort(compareByName).map(f => (
        <Folder
          folderId={f.id}
          setShowFileModal={setShowFileModal}
          setShowEditFolderModal={setShowEditFolderModal}
          setCurrentFolderId={setCurrentFolderId}
          currentFolderLevel={currentFolderLevel}
          setCurrentFolderLevel={setCurrentFolderLevel}
        />
      ))}
    </ul>
  );
};
