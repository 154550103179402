import CKEditor from "ckeditor4-react";
import { sv } from "date-fns/esm/locale";
import { Box, Button, CheckBox, Form, FormField, TextInput } from "grommet";
import { Save } from "grommet-icons";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useGetMeetingQuery,
  useUpdateMeetingMutation
} from "../generated/graphql";
registerLocale("sv", sv);

interface Props {
  showModal: boolean;
  setShowModal(s: boolean): void;
  meetingId?: string;
}

interface CustomInputProps {
  value?: string;
  onClick?: () => any;
}

export const EditMeeting: React.FC<Props> = ({
  setShowModal,
  meetingId = ""
}) => {
  const [privilegedChecked, setPrivilegedChecked] = useState(false);
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);

  const [updateMeeting] = useUpdateMeetingMutation({
    refetchQueries: [`Meetings`]
  });

  const { data } = useGetMeetingQuery({
    variables: { id: meetingId }
  });
  useEffect(() => {
    console.log(meetingId);
    if (data && data.getMeeting) {
      const meeting = data.getMeeting;
      setTitle(meeting.title);
      setContent(meeting.content || "");
      setLocation(meeting.location);
      setDate(new Date(meeting.date));
      setUrl(meeting.url || "");

      setLoading(false);
    }
  }, [meetingId, data]);

  if (loading) {
    return null;
  }

  return (
    <Box pad="large">
      <h2>Redigera aktivitet</h2>
      <Form>
        <FormField label="Dag &amp; Tid">
          <DatePicker
            placeholderText="Välj datum och tid"
            selected={date}
            onChange={date => {
              setDate(date);
              console.log(date);
            }}
            showTimeSelect
            locale="sv"
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Tid"
            dateFormat="MMMM d, yyyy HH:mm"
          />
        </FormField>
        <FormField label="Plats">
          <TextInput
            value={location}
            placeholder="skriv här"
            onChange={({ target }) => {
              setLocation(target.value);
            }}
          />
        </FormField>
        <FormField label="Titel">
          <TextInput
            value={title}
            placeholder="skriv här"
            onChange={({ target }) => {
              setTitle(target.value);
            }}
          />
        </FormField>
        <FormField label="Beskrivning (valfritt)">
          <CKEditor
            // onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
            data={content}
            onChange={evt => {
              if (evt.editor) {
                // const serialisedContent = btoa(evt.editor.getData());
                // localStorage.setItem(
                //   "serializedHtmlContent",
                //   serialisedContent
                // );
                setContent(evt.editor.getData());
              }
            }}
            config={{
              height: "100px",
              language: "sv",
              toolbar: [
                ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                ["Link", "Unlink"],
                ["Copy", "Cut", "Paste"]
              ]
            }}
            // type="inline"
            style={{
              padding: 10,
              minHeight: "100px"
            }}
          />
        </FormField>
        <FormField label="Länk (valfritt)">
          <TextInput
            value={url}
            placeholder="inkl http/https"
            onChange={({ target }) => {
              setUrl(target.value);
            }}
          />
        </FormField>

        <CheckBox
          checked={privilegedChecked}
          label="Visa endast för priviligerade användare"
          onChange={() => setPrivilegedChecked(!privilegedChecked)}
        />
        <hr />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            primary
            icon={<Save />}
            label="Spara"
            onClick={async () => {
              try {
                updateMeeting({
                  variables: {
                    id: meetingId,
                    location,
                    title,
                    content,
                    url,
                    date: date.toString()
                  }
                });
                // createFolder({
                //   variables: {
                //     companyId,
                //     name,
                //     isPrivileged: privilegedChecked
                //   }
                // });
                // createArticle({
                //   variables: {
                //     title: title,
                //     content: htmlContent,
                //     sms: smsChecked,
                //     mail: mailChecked,
                //     isPrivileged: privilegedChecked
                //   }
                // });
                setShowModal(false);
              } catch (error) {
                console.log(error);
              }
            }}
          />
          <Button label="Stäng" onClick={() => setShowModal(false)} />
        </div>
      </Form>
    </Box>
  );
};
