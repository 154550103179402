import { Box } from "grommet";
import {
  Document,
  DocumentCsv,
  DocumentImage,
  DocumentPdf,
  DocumentPpt,
  Trash
} from "grommet-icons";
import React, { useContext } from "react";
import { theme } from "../../App";
import { c } from "../../config";
import { UserContext } from "../../contexts/UserContext";
import { useDeleteFileMutation } from "../../generated/graphql";
import { truncateFilename } from "../../utils/truncateString";

interface Props {
  file?: any;

  folderId?: string;
}

export const FileRow: React.FC<Props> = ({ file, folderId }) => {
  const [deleteFile] = useDeleteFileMutation({
    refetchQueries: ["Company", "Folder", "Article"]
  });
  const { brand } = theme.global.colors;
  const { user } = useContext(UserContext);
  if (
    file.isPrivileged === false ||
    file.isPrivileged === undefined ||
    (file.isPrivileged && user && (user.isPrivileged || user.isAdmin))
  ) {
    // icon
    // console.log(file.mimetype);
    let fileIcon;
    switch (file.mimetype) {
      case "image/jpeg":
      case "image/png":
        fileIcon = <DocumentImage color={brand} />;
        break;

      case "application/pdf":
        fileIcon = <DocumentPdf color={brand} />;
        break;

      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        fileIcon = <DocumentPpt color={brand} />;
        break;

      case "text/csv":
        fileIcon = <DocumentCsv color={brand} />;
        break;
      default:
        fileIcon = <Document color={brand} />;
        break;
    }

    return (
      <li
        style={{
          padding: "4px 0"
        }}
      >
        <Box direction="row" fill justify="stretch">
          {fileIcon}

          <a
            href={`${c.REACT_APP_BACKEND_URL}/file/${folderId}/${file.filename}`}
            rel="noopener noreferrer"
            target="_blank"
            style={{
              flexGrow: 1,
              color: brand,
              textDecoration: "none",
              marginLeft: "8px"
            }}
          >
            {truncateFilename(file.filename, 27)}
          </a>
          {user && user.isAdmin && (
            <div
              style={{ float: "right" }}
              onClick={() => {
                if (
                  window.confirm(`Vill du verkligen ta bort ${file.filename}?`)
                ) {
                  deleteFile({ variables: { id: file.id } });
                }
              }}
            >
              <Trash color="rgba(0,0,0,0.3)" />
            </div>
          )}
        </Box>
      </li>
    );
  } else {
    console.log("FICK INTE VISA!");
    return null;
  }
};
