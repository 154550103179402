import CKEditor from "ckeditor4-react";
import { Box, Button, Form, FormField, TextInput } from "grommet";
import { Save } from "grommet-icons";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useCreateCompanyMutation } from "../generated/graphql";

interface Props {
  showModal: boolean;
  setShowModal(s: boolean): void;
}

export const CreatePCompany: React.FC<Props> = ({ setShowModal }) => {
  // const [mailChecked, setMailChecked] = useState(false);
  // const [smsChecked, setSmsChecked] = useState(false);
  // const [privilegedChecked, setPrivilegedChecked] = useState(false);

  const [name, setName] = useState("");
  const [logo, setLogo] = useState();
  const onDrop = useCallback(([file]) => {
    setLogo(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });
  const [description, setDescription] = useState("");

  const [createPCompany] = useCreateCompanyMutation({
    refetchQueries: ["Companies"]
  });

  return (
    <Box pad="large">
      <h2>Skapa portföljbolag</h2>
      <Form>
        <FormField label="Bolagsnamn">
          <TextInput
            placeholder="skriv här"
            onChange={({ target }) => {
              setName(target.value);
            }}
          />
        </FormField>
        <FormField label="Beskrivning">
          {/* <TextInput
            placeholder="skriv här"
            onChange={({ target }) => {
              setDescription(target.value);
            }}
          /> */}
          <CKEditor
            // onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
            data={description}
            onChange={evt => {
              if (evt.editor) {
                // const serialisedContent = btoa(evt.editor.getData());
                // localStorage.setItem(
                //   "serializedHtmlContent",
                //   serialisedContent
                // );
                setDescription(evt.editor.getData());
              }
            }}
            config={{
              height: "100px",
              language: "sv",
              toolbar: [
                ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                ["Link", "Unlink"],
                ["Copy", "Cut", "Paste"]
              ]
            }}
            // type="inline"
            style={{
              padding: 10,
              minHeight: "100px"
            }}
          />
        </FormField>
        <FormField label="Logga">
          <div
            {...getRootProps()}
            style={{
              border: "2px dotted #efefef",
              padding: "15px",
              textAlign: "center",
              marginBottom: "20px"
            }}
          >
            <input {...getInputProps()} />
            {isDragActive && <p>Släpp här ...</p>}
            {logo ? (
              logo.name
            ) : (
              <p>Dra hit en fil eller klicka för att välja</p>
            )}
          </div>
        </FormField>

        {/* <FormField>
          <CheckBox
            checked={mailChecked}
            label="Skicka nyheten som mejl"
            onChange={() => setMailChecked(!mailChecked)}
          />
          <CheckBox
            checked={smsChecked}
            label="Skicka nyheten som sms"
            onChange={() => setSmsChecked(!smsChecked)}
          />
          <CheckBox
            checked={privilegedChecked}
            label="Visa endast för priviligerade användare"
            onChange={() => setPrivilegedChecked(!privilegedChecked)}
          />
        </FormField> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            primary
            icon={<Save />}
            label="Spara"
            onClick={async () => {
              try {
                createPCompany({
                  variables: {
                    name,
                    description,
                    logo,
                    type: "portfolio"
                  }
                });
                // createArticle({
                //   variables: {
                //     title: title,
                //     content: htmlContent,
                //     sms: smsChecked,
                //     mail: mailChecked,
                //     isPrivileged: privilegedChecked
                //   }
                // });
                setShowModal(false);
              } catch (error) {}
            }}
          />
          <Button label="Stäng" onClick={() => setShowModal(false)} />
        </div>
      </Form>
    </Box>
  );
};
