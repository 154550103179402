import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};

export type Article = {
   __typename?: 'Article',
  id: Scalars['String'],
  title: Scalars['String'],
  content: Scalars['String'],
  date: Scalars['Timestamp'],
  files?: Maybe<Array<File>>,
  company?: Maybe<Company>,
  mailMessage?: Maybe<Array<MailMessage>>,
  isPrivileged: Scalars['Boolean'],
};

export type Company = {
   __typename?: 'Company',
  id: Scalars['String'],
  type: Scalars['String'],
  name: Scalars['String'],
  description: Scalars['String'],
  logo?: Maybe<Scalars['String']>,
  links?: Maybe<Array<Link>>,
  folders?: Maybe<Array<Folder>>,
  articles?: Maybe<Array<Article>>,
  isPrivileged: Scalars['Boolean'],
};

export type Event = {
   __typename?: 'Event',
  id: Scalars['String'],
  action: Scalars['String'],
  date: Scalars['Timestamp'],
  user?: Maybe<User>,
};

export type File = {
   __typename?: 'File',
  id: Scalars['String'],
  filename: Scalars['String'],
  mimetype: Scalars['String'],
  folder: Folder,
  meeting: Meeting,
  article: Article,
  isPrivileged: Scalars['Boolean'],
};

export type Folder = {
   __typename?: 'Folder',
  id: Scalars['String'],
  name: Scalars['String'],
  files?: Maybe<Array<File>>,
  company?: Maybe<Company>,
  isPrivileged?: Maybe<Scalars['Boolean']>,
  parent?: Maybe<Folder>,
  children?: Maybe<Array<Folder>>,
};

export type Link = {
   __typename?: 'Link',
  id: Scalars['String'],
  name: Scalars['String'],
  url: Scalars['String'],
  company?: Maybe<Company>,
  isPrivileged?: Maybe<Scalars['Boolean']>,
};

export type LoginResponse = {
   __typename?: 'LoginResponse',
  accessToken: Scalars['String'],
  user: User,
};

export type MailMessage = {
   __typename?: 'MailMessage',
  id: Scalars['String'],
  articles?: Maybe<Array<Article>>,
  isPrivileged?: Maybe<Scalars['Boolean']>,
  isSent?: Maybe<Scalars['Boolean']>,
};

export type Meeting = {
   __typename?: 'Meeting',
  id: Scalars['String'],
  date: Scalars['Timestamp'],
  title: Scalars['String'],
  content?: Maybe<Scalars['String']>,
  location: Scalars['String'],
  url?: Maybe<Scalars['String']>,
  files?: Maybe<Array<File>>,
  isPrivileged: Scalars['Boolean'],
};

export type Mutation = {
   __typename?: 'Mutation',
  updateArticle: Scalars['Boolean'],
  createArticle: Scalars['Boolean'],
  deleteArticle: Scalars['Boolean'],
  addFileToArticle: Scalars['Boolean'],
  deleteFileFromArticle: Scalars['Boolean'],
  updateCompany: Scalars['Boolean'],
  createCompany: Scalars['Boolean'],
  deleteCompany: Scalars['Boolean'],
  addLinkToCompany: Scalars['Boolean'],
  deleteLinkFromCompany: Scalars['Boolean'],
  uploadFile: Scalars['Boolean'],
  deleteFile: Scalars['Boolean'],
  updateFolder: Scalars['Boolean'],
  createFolder: Scalars['Boolean'],
  deleteFolder: Scalars['Boolean'],
  deleteAllMail: Scalars['Boolean'],
  createMailMessage: Scalars['Boolean'],
  createMeeting: Scalars['Boolean'],
  updateMeeting: Scalars['Boolean'],
  addFileToMeeting: Scalars['Boolean'],
  deleteMeeting: Scalars['Boolean'],
  updateUser: Scalars['Boolean'],
  register: Scalars['Boolean'],
  login: LoginResponse,
  revokeRefreshTokensForUser: Scalars['Boolean'],
  logout: Scalars['Boolean'],
  deleteUser: Scalars['Boolean'],
  forgotPassword: Scalars['Boolean'],
};


export type MutationUpdateArticleArgs = {
  mail?: Maybe<Scalars['Boolean']>,
  sms?: Maybe<Scalars['Boolean']>,
  isPrivileged?: Maybe<Scalars['Boolean']>,
  companyId?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  title: Scalars['String'],
  id: Scalars['String']
};


export type MutationCreateArticleArgs = {
  mail?: Maybe<Scalars['Boolean']>,
  sms?: Maybe<Scalars['Boolean']>,
  companyId?: Maybe<Scalars['String']>,
  isPrivileged?: Maybe<Scalars['Boolean']>,
  content: Scalars['String'],
  title: Scalars['String']
};


export type MutationDeleteArticleArgs = {
  id: Scalars['String']
};


export type MutationAddFileToArticleArgs = {
  file: Scalars['Upload'],
  articleId: Scalars['String']
};


export type MutationDeleteFileFromArticleArgs = {
  fileId: Scalars['String']
};


export type MutationUpdateCompanyArgs = {
  logo?: Maybe<Scalars['Upload']>,
  description: Scalars['String'],
  name: Scalars['String'],
  id: Scalars['String']
};


export type MutationCreateCompanyArgs = {
  logo?: Maybe<Scalars['Upload']>,
  isPrivileged?: Maybe<Scalars['Boolean']>,
  description: Scalars['String'],
  type: Scalars['String'],
  name: Scalars['String']
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['String']
};


export type MutationAddLinkToCompanyArgs = {
  url: Scalars['String'],
  name: Scalars['String'],
  companyId: Scalars['String']
};


export type MutationDeleteLinkFromCompanyArgs = {
  linkId: Scalars['String']
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'],
  isPrivileged?: Maybe<Scalars['Boolean']>,
  folderId: Scalars['String']
};


export type MutationDeleteFileArgs = {
  id: Scalars['String']
};


export type MutationUpdateFolderArgs = {
  isPrivileged?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  id: Scalars['String']
};


export type MutationCreateFolderArgs = {
  isPrivileged?: Maybe<Scalars['Boolean']>,
  parentId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  company: Scalars['String']
};


export type MutationDeleteFolderArgs = {
  id: Scalars['String']
};


export type MutationCreateMailMessageArgs = {
  welcometext?: Maybe<Scalars['String']>,
  articles: Array<Scalars['String']>,
  isPrivileged?: Maybe<Scalars['Boolean']>
};


export type MutationCreateMeetingArgs = {
  mail?: Maybe<Scalars['Boolean']>,
  sms?: Maybe<Scalars['Boolean']>,
  isPrivileged?: Maybe<Scalars['Boolean']>,
  url?: Maybe<Scalars['String']>,
  location: Scalars['String'],
  content?: Maybe<Scalars['String']>,
  date: Scalars['String'],
  title: Scalars['String']
};


export type MutationUpdateMeetingArgs = {
  mail?: Maybe<Scalars['Boolean']>,
  sms?: Maybe<Scalars['Boolean']>,
  isPrivileged?: Maybe<Scalars['Boolean']>,
  url?: Maybe<Scalars['String']>,
  location?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  id: Scalars['String']
};


export type MutationAddFileToMeetingArgs = {
  file: Scalars['Upload'],
  meetingId: Scalars['String']
};


export type MutationDeleteMeetingArgs = {
  id: Scalars['String']
};


export type MutationUpdateUserArgs = {
  isPrivileged?: Maybe<Scalars['Boolean']>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  mobile?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  token?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  id: Scalars['String']
};


export type MutationRegisterArgs = {
  isPrivileged?: Maybe<Scalars['Boolean']>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  mobile?: Maybe<Scalars['String']>,
  lastName: Scalars['String'],
  firstName: Scalars['String'],
  password: Scalars['String'],
  email: Scalars['String']
};


export type MutationLoginArgs = {
  password: Scalars['String'],
  email: Scalars['String']
};


export type MutationRevokeRefreshTokensForUserArgs = {
  userId: Scalars['String']
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']
};

export type Query = {
   __typename?: 'Query',
  helloArticle: Scalars['String'],
  articles: Array<Article>,
  getArticle?: Maybe<Article>,
  companies?: Maybe<Array<Company>>,
  company?: Maybe<Company>,
  events: Array<Event>,
  folders: Array<Folder>,
  folder: Folder,
  mails: Array<MailMessage>,
  meetings: Array<Meeting>,
  getMeeting?: Maybe<Meeting>,
  hello: Scalars['String'],
  bye: Scalars['String'],
  me?: Maybe<User>,
  users: Array<User>,
  user: User,
  fetchToken: Token,
};


export type QueryArticlesArgs = {
  isPrivileged?: Maybe<Scalars['Boolean']>,
  limit?: Maybe<Scalars['Float']>
};


export type QueryGetArticleArgs = {
  id: Scalars['String']
};


export type QueryCompaniesArgs = {
  type?: Maybe<Scalars['String']>
};


export type QueryCompanyArgs = {
  id: Scalars['String']
};


export type QueryFolderArgs = {
  id: Scalars['String']
};


export type QueryMeetingsArgs = {
  historic?: Maybe<Scalars['Boolean']>,
  isPrivileged?: Maybe<Scalars['Boolean']>,
  limit?: Maybe<Scalars['Float']>
};


export type QueryGetMeetingArgs = {
  id: Scalars['String']
};


export type QueryUserArgs = {
  id: Scalars['String']
};


export type QueryFetchTokenArgs = {
  token: Scalars['String']
};


export type Token = {
   __typename?: 'Token',
  id: Scalars['String'],
  userId: Scalars['String'],
};


export type User = {
   __typename?: 'User',
  id: Scalars['String'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  mobile: Scalars['String'],
  logins: Scalars['Float'],
  latestLogin?: Maybe<Scalars['Timestamp']>,
  isAdmin: Scalars['Boolean'],
  isPrivileged: Scalars['Boolean'],
  events?: Maybe<Array<Event>>,
};
export type ArticlesQueryVariables = {
  limit?: Maybe<Scalars['Float']>,
  isPrivileged?: Maybe<Scalars['Boolean']>
};


export type ArticlesQuery = (
  { __typename?: 'Query' }
  & { articles: Array<(
    { __typename?: 'Article' }
    & Pick<Article, 'id' | 'title' | 'content' | 'date' | 'isPrivileged'>
    & { company: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>, files: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'mimetype' | 'isPrivileged'>
    )>> }
  )> }
);

export type CreateArticleMutationVariables = {
  title: Scalars['String'],
  content: Scalars['String'],
  companyId?: Maybe<Scalars['String']>,
  sms?: Maybe<Scalars['Boolean']>,
  mail?: Maybe<Scalars['Boolean']>,
  isPrivileged?: Maybe<Scalars['Boolean']>
};


export type CreateArticleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createArticle'>
);

export type UpdateArticleMutationVariables = {
  id: Scalars['String'],
  companyId?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  content: Scalars['String'],
  sms?: Maybe<Scalars['Boolean']>,
  mail?: Maybe<Scalars['Boolean']>,
  isPrivileged?: Maybe<Scalars['Boolean']>
};


export type UpdateArticleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateArticle'>
);

export type ArticleQueryVariables = {
  id: Scalars['String']
};


export type ArticleQuery = (
  { __typename?: 'Query' }
  & { getArticle: Maybe<(
    { __typename?: 'Article' }
    & Pick<Article, 'id' | 'title' | 'content' | 'date' | 'isPrivileged'>
    & { company: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>, files: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'mimetype' | 'isPrivileged'>
    )>> }
  )> }
);

export type DeleteArticleMutationVariables = {
  id: Scalars['String']
};


export type DeleteArticleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteArticle'>
);

export type AddFileToArticleMutationVariables = {
  articleId: Scalars['String'],
  file: Scalars['Upload']
};


export type AddFileToArticleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addFileToArticle'>
);

export type CreateMailMessageMutationVariables = {
  articles: Array<Scalars['String']>,
  welcometext: Scalars['String']
};


export type CreateMailMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMailMessage'>
);

export type ByeQueryVariables = {};


export type ByeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bye'>
);

export type CompaniesQueryVariables = {
  type?: Maybe<Scalars['String']>
};


export type CompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: Maybe<Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'description' | 'logo'>
  )>> }
);

export type CompanyQueryVariables = {
  id: Scalars['String']
};


export type CompanyQuery = (
  { __typename?: 'Query' }
  & { company: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'description' | 'logo'>
    & { links: Maybe<Array<(
      { __typename?: 'Link' }
      & Pick<Link, 'id' | 'name' | 'url' | 'isPrivileged'>
    )>>, folders: Maybe<Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name' | 'isPrivileged'>
      & { files: Maybe<Array<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'filename' | 'mimetype' | 'isPrivileged'>
      )>> }
    )>>, articles: Maybe<Array<(
      { __typename?: 'Article' }
      & Pick<Article, 'id' | 'title' | 'date'>
    )>> }
  )> }
);

export type CreateCompanyMutationVariables = {
  logo?: Maybe<Scalars['Upload']>,
  name: Scalars['String'],
  description: Scalars['String'],
  type: Scalars['String']
};


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCompany'>
);

export type UpdateCompanyMutationVariables = {
  logo?: Maybe<Scalars['Upload']>,
  id: Scalars['String'],
  name: Scalars['String'],
  description: Scalars['String']
};


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompany'>
);

export type DeleteCompanyMutationVariables = {
  id: Scalars['String']
};


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompany'>
);

export type AddLinkToCompanyMutationVariables = {
  companyId: Scalars['String'],
  name: Scalars['String'],
  url: Scalars['String']
};


export type AddLinkToCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addLinkToCompany'>
);

export type DeleteLinkFromCompanyMutationVariables = {
  linkId: Scalars['String']
};


export type DeleteLinkFromCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLinkFromCompany'>
);

export type DeleteFileMutationVariables = {
  id: Scalars['String']
};


export type DeleteFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFile'>
);

export type EventsQueryVariables = {};


export type EventsQuery = (
  { __typename?: 'Query' }
  & { events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'date' | 'action'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    )> }
  )> }
);

export type CreateFolderMutationVariables = {
  companyId: Scalars['String'],
  parentId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  isPrivileged?: Maybe<Scalars['Boolean']>
};


export type CreateFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFolder'>
);

export type DeleteFolderMutationVariables = {
  id: Scalars['String']
};


export type DeleteFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFolder'>
);

export type FolderQueryVariables = {
  id: Scalars['String']
};


export type FolderQuery = (
  { __typename?: 'Query' }
  & { folder: (
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'isPrivileged'>
    & { children: Maybe<Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name' | 'isPrivileged'>
    )>>, files: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'mimetype' | 'filename' | 'isPrivileged'>
    )>> }
  ) }
);

export type UpdateFolderMutationVariables = {
  id: Scalars['String'],
  name: Scalars['String'],
  isPrivileged?: Maybe<Scalars['Boolean']>
};


export type UpdateFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFolder'>
);

export type HelloQueryVariables = {};


export type HelloQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'hello'>
);

export type LoginMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'mobile' | 'firstName' | 'lastName' | 'isAdmin' | 'isPrivileged'>
    ) }
  ) }
);

export type LogoutMutationVariables = {};


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MeQueryVariables = {};


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'mobile' | 'firstName' | 'lastName' | 'isAdmin' | 'isPrivileged'>
  )> }
);

export type MeetingsQueryVariables = {
  limit?: Maybe<Scalars['Float']>,
  historic?: Maybe<Scalars['Boolean']>
};


export type MeetingsQuery = (
  { __typename?: 'Query' }
  & { meetings: Array<(
    { __typename?: 'Meeting' }
    & Pick<Meeting, 'id' | 'title' | 'date' | 'content' | 'location' | 'url' | 'isPrivileged'>
    & { files: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'mimetype' | 'isPrivileged'>
    )>> }
  )> }
);

export type GetMeetingQueryVariables = {
  id: Scalars['String']
};


export type GetMeetingQuery = (
  { __typename?: 'Query' }
  & { getMeeting: Maybe<(
    { __typename?: 'Meeting' }
    & Pick<Meeting, 'id' | 'title' | 'date' | 'content' | 'location' | 'url' | 'isPrivileged'>
    & { files: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'mimetype' | 'isPrivileged'>
    )>> }
  )> }
);

export type CreateMeetingMutationVariables = {
  location: Scalars['String'],
  title: Scalars['String'],
  date: Scalars['String'],
  content?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>
};


export type CreateMeetingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMeeting'>
);

export type UpdateMeetingMutationVariables = {
  location?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  id: Scalars['String']
};


export type UpdateMeetingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMeeting'>
);

export type DeleteMeetingMutationVariables = {
  id: Scalars['String']
};


export type DeleteMeetingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMeeting'>
);

export type AddFileToMeetingMutationVariables = {
  meetingId: Scalars['String'],
  file: Scalars['Upload']
};


export type AddFileToMeetingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addFileToMeeting'>
);

export type FetchTokenQueryVariables = {
  token: Scalars['String']
};


export type FetchTokenQuery = (
  { __typename?: 'Query' }
  & { fetchToken: (
    { __typename?: 'Token' }
    & Pick<Token, 'id' | 'userId'>
  ) }
);

export type UploadFileMutationVariables = {
  file: Scalars['Upload'],
  folderId: Scalars['String'],
  isPrivileged?: Maybe<Scalars['Boolean']>
};


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadFile'>
);

export type UsersQueryVariables = {};


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'isAdmin' | 'isPrivileged' | 'logins' | 'latestLogin'>
  )> }
);

export type GetUserQueryVariables = {
  id: Scalars['String']
};


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'isAdmin' | 'isPrivileged'>
  ) }
);

export type DeleteUserMutationVariables = {
  id: Scalars['String']
};


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type UpdateUserMutationVariables = {
  email?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  token?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  mobile?: Maybe<Scalars['String']>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  isPrivileged?: Maybe<Scalars['Boolean']>
};


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUser'>
);

export type RegisterMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  mobile?: Maybe<Scalars['String']>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  isPrivileged?: Maybe<Scalars['Boolean']>
};


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'register'>
);

export type ForgotPasswordMutationVariables = {
  email: Scalars['String']
};


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export const ArticlesDocument = gql`
    query Articles($limit: Float, $isPrivileged: Boolean) {
  articles(limit: $limit, isPrivileged: $isPrivileged) {
    id
    title
    content
    date
    isPrivileged
    company {
      id
      name
    }
    files {
      id
      filename
      mimetype
      isPrivileged
    }
  }
}
    `;

    export function useArticlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
      return ApolloReactHooks.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, baseOptions);
    }
      export function useArticlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, baseOptions);
      }
      
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesQueryResult = ApolloReactCommon.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($title: String!, $content: String!, $companyId: String, $sms: Boolean, $mail: Boolean, $isPrivileged: Boolean) {
  createArticle(title: $title, content: $content, isPrivileged: $isPrivileged, sms: $sms, mail: $mail, companyId: $companyId)
}
    `;
export type CreateArticleMutationFn = ApolloReactCommon.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;

    export function useCreateArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
      return ApolloReactHooks.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, baseOptions);
    }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = ApolloReactCommon.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($id: String!, $companyId: String, $title: String!, $content: String!, $sms: Boolean, $mail: Boolean, $isPrivileged: Boolean) {
  updateArticle(id: $id, title: $title, content: $content, isPrivileged: $isPrivileged, sms: $sms, mail: $mail, companyId: $companyId)
}
    `;
export type UpdateArticleMutationFn = ApolloReactCommon.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

    export function useUpdateArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, baseOptions);
    }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = ApolloReactCommon.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const ArticleDocument = gql`
    query Article($id: String!) {
  getArticle(id: $id) {
    id
    title
    content
    date
    isPrivileged
    company {
      id
      name
    }
    files {
      id
      filename
      mimetype
      isPrivileged
    }
  }
}
    `;

    export function useArticleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
      return ApolloReactHooks.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
    }
      export function useArticleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
      }
      
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleQueryResult = ApolloReactCommon.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const DeleteArticleDocument = gql`
    mutation DeleteArticle($id: String!) {
  deleteArticle(id: $id)
}
    `;
export type DeleteArticleMutationFn = ApolloReactCommon.MutationFunction<DeleteArticleMutation, DeleteArticleMutationVariables>;

    export function useDeleteArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(DeleteArticleDocument, baseOptions);
    }
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = ApolloReactCommon.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArticleMutation, DeleteArticleMutationVariables>;
export const AddFileToArticleDocument = gql`
    mutation AddFileToArticle($articleId: String!, $file: Upload!) {
  addFileToArticle(articleId: $articleId, file: $file)
}
    `;
export type AddFileToArticleMutationFn = ApolloReactCommon.MutationFunction<AddFileToArticleMutation, AddFileToArticleMutationVariables>;

    export function useAddFileToArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFileToArticleMutation, AddFileToArticleMutationVariables>) {
      return ApolloReactHooks.useMutation<AddFileToArticleMutation, AddFileToArticleMutationVariables>(AddFileToArticleDocument, baseOptions);
    }
export type AddFileToArticleMutationHookResult = ReturnType<typeof useAddFileToArticleMutation>;
export type AddFileToArticleMutationResult = ApolloReactCommon.MutationResult<AddFileToArticleMutation>;
export type AddFileToArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFileToArticleMutation, AddFileToArticleMutationVariables>;
export const CreateMailMessageDocument = gql`
    mutation CreateMailMessage($articles: [String!]!, $welcometext: String!) {
  createMailMessage(articles: $articles, welcometext: $welcometext)
}
    `;
export type CreateMailMessageMutationFn = ApolloReactCommon.MutationFunction<CreateMailMessageMutation, CreateMailMessageMutationVariables>;

    export function useCreateMailMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMailMessageMutation, CreateMailMessageMutationVariables>) {
      return ApolloReactHooks.useMutation<CreateMailMessageMutation, CreateMailMessageMutationVariables>(CreateMailMessageDocument, baseOptions);
    }
export type CreateMailMessageMutationHookResult = ReturnType<typeof useCreateMailMessageMutation>;
export type CreateMailMessageMutationResult = ApolloReactCommon.MutationResult<CreateMailMessageMutation>;
export type CreateMailMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMailMessageMutation, CreateMailMessageMutationVariables>;
export const ByeDocument = gql`
    query Bye {
  bye
}
    `;

    export function useByeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ByeQuery, ByeQueryVariables>) {
      return ApolloReactHooks.useQuery<ByeQuery, ByeQueryVariables>(ByeDocument, baseOptions);
    }
      export function useByeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ByeQuery, ByeQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ByeQuery, ByeQueryVariables>(ByeDocument, baseOptions);
      }
      
export type ByeQueryHookResult = ReturnType<typeof useByeQuery>;
export type ByeQueryResult = ApolloReactCommon.QueryResult<ByeQuery, ByeQueryVariables>;
export const CompaniesDocument = gql`
    query Companies($type: String) {
  companies(type: $type) {
    id
    name
    description
    logo
  }
}
    `;

    export function useCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
      return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
    }
      export function useCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
      }
      
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesQueryResult = ApolloReactCommon.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyDocument = gql`
    query Company($id: String!) {
  company(id: $id) {
    id
    name
    description
    logo
    links {
      id
      name
      url
      isPrivileged
    }
    folders {
      id
      name
      isPrivileged
      files {
        id
        filename
        mimetype
        isPrivileged
      }
    }
    articles {
      id
      title
      date
    }
  }
}
    `;

    export function useCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
      return ApolloReactHooks.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
    }
      export function useCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
      }
      
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyQueryResult = ApolloReactCommon.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($logo: Upload, $name: String!, $description: String!, $type: String!) {
  createCompany(name: $name, description: $description, type: $type, logo: $logo)
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

    export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
      return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
    }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($logo: Upload, $id: String!, $name: String!, $description: String!) {
  updateCompany(id: $id, name: $name, description: $description, logo: $logo)
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

    export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
    }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: String!) {
  deleteCompany(id: $id)
}
    `;
export type DeleteCompanyMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

    export function useDeleteCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, baseOptions);
    }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const AddLinkToCompanyDocument = gql`
    mutation AddLinkToCompany($companyId: String!, $name: String!, $url: String!) {
  addLinkToCompany(url: $url, name: $name, companyId: $companyId)
}
    `;
export type AddLinkToCompanyMutationFn = ApolloReactCommon.MutationFunction<AddLinkToCompanyMutation, AddLinkToCompanyMutationVariables>;

    export function useAddLinkToCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddLinkToCompanyMutation, AddLinkToCompanyMutationVariables>) {
      return ApolloReactHooks.useMutation<AddLinkToCompanyMutation, AddLinkToCompanyMutationVariables>(AddLinkToCompanyDocument, baseOptions);
    }
export type AddLinkToCompanyMutationHookResult = ReturnType<typeof useAddLinkToCompanyMutation>;
export type AddLinkToCompanyMutationResult = ApolloReactCommon.MutationResult<AddLinkToCompanyMutation>;
export type AddLinkToCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AddLinkToCompanyMutation, AddLinkToCompanyMutationVariables>;
export const DeleteLinkFromCompanyDocument = gql`
    mutation DeleteLinkFromCompany($linkId: String!) {
  deleteLinkFromCompany(linkId: $linkId)
}
    `;
export type DeleteLinkFromCompanyMutationFn = ApolloReactCommon.MutationFunction<DeleteLinkFromCompanyMutation, DeleteLinkFromCompanyMutationVariables>;

    export function useDeleteLinkFromCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLinkFromCompanyMutation, DeleteLinkFromCompanyMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteLinkFromCompanyMutation, DeleteLinkFromCompanyMutationVariables>(DeleteLinkFromCompanyDocument, baseOptions);
    }
export type DeleteLinkFromCompanyMutationHookResult = ReturnType<typeof useDeleteLinkFromCompanyMutation>;
export type DeleteLinkFromCompanyMutationResult = ApolloReactCommon.MutationResult<DeleteLinkFromCompanyMutation>;
export type DeleteLinkFromCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLinkFromCompanyMutation, DeleteLinkFromCompanyMutationVariables>;
export const DeleteFileDocument = gql`
    mutation DeleteFile($id: String!) {
  deleteFile(id: $id)
}
    `;
export type DeleteFileMutationFn = ApolloReactCommon.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

    export function useDeleteFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, baseOptions);
    }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = ApolloReactCommon.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const EventsDocument = gql`
    query Events {
  events {
    date
    action
    user {
      firstName
      lastName
    }
  }
}
    `;

    export function useEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
      return ApolloReactHooks.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
    }
      export function useEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
      }
      
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsQueryResult = ApolloReactCommon.QueryResult<EventsQuery, EventsQueryVariables>;
export const CreateFolderDocument = gql`
    mutation CreateFolder($companyId: String!, $parentId: String, $name: String!, $isPrivileged: Boolean) {
  createFolder(parentId: $parentId, company: $companyId, name: $name, isPrivileged: $isPrivileged)
}
    `;
export type CreateFolderMutationFn = ApolloReactCommon.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

    export function useCreateFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>) {
      return ApolloReactHooks.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, baseOptions);
    }
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = ApolloReactCommon.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFolderMutation, CreateFolderMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($id: String!) {
  deleteFolder(id: $id)
}
    `;
export type DeleteFolderMutationFn = ApolloReactCommon.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

    export function useDeleteFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, baseOptions);
    }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = ApolloReactCommon.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const FolderDocument = gql`
    query Folder($id: String!) {
  folder(id: $id) {
    id
    name
    isPrivileged
    children {
      id
      name
      isPrivileged
    }
    files {
      id
      mimetype
      filename
      isPrivileged
    }
  }
}
    `;

    export function useFolderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolderQuery, FolderQueryVariables>) {
      return ApolloReactHooks.useQuery<FolderQuery, FolderQueryVariables>(FolderDocument, baseOptions);
    }
      export function useFolderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolderQuery, FolderQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<FolderQuery, FolderQueryVariables>(FolderDocument, baseOptions);
      }
      
export type FolderQueryHookResult = ReturnType<typeof useFolderQuery>;
export type FolderQueryResult = ApolloReactCommon.QueryResult<FolderQuery, FolderQueryVariables>;
export const UpdateFolderDocument = gql`
    mutation UpdateFolder($id: String!, $name: String!, $isPrivileged: Boolean) {
  updateFolder(id: $id, name: $name, isPrivileged: $isPrivileged)
}
    `;
export type UpdateFolderMutationFn = ApolloReactCommon.MutationFunction<UpdateFolderMutation, UpdateFolderMutationVariables>;

    export function useUpdateFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(UpdateFolderDocument, baseOptions);
    }
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationResult = ApolloReactCommon.MutationResult<UpdateFolderMutation>;
export type UpdateFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFolderMutation, UpdateFolderMutationVariables>;
export const HelloDocument = gql`
    query Hello {
  hello
}
    `;

    export function useHelloQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HelloQuery, HelloQueryVariables>) {
      return ApolloReactHooks.useQuery<HelloQuery, HelloQueryVariables>(HelloDocument, baseOptions);
    }
      export function useHelloLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HelloQuery, HelloQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<HelloQuery, HelloQueryVariables>(HelloDocument, baseOptions);
      }
      
export type HelloQueryHookResult = ReturnType<typeof useHelloQuery>;
export type HelloQueryResult = ApolloReactCommon.QueryResult<HelloQuery, HelloQueryVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    user {
      id
      email
      mobile
      firstName
      lastName
      isAdmin
      isPrivileged
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

    export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
      return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
    }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

    export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
      return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
    }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    email
    mobile
    firstName
    lastName
    isAdmin
    isPrivileged
  }
}
    `;

    export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
      return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
    }
      export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
      
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const MeetingsDocument = gql`
    query Meetings($limit: Float, $historic: Boolean) {
  meetings(limit: $limit, historic: $historic) {
    id
    title
    date
    content
    location
    url
    isPrivileged
    files {
      id
      filename
      mimetype
      isPrivileged
    }
  }
}
    `;

    export function useMeetingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeetingsQuery, MeetingsQueryVariables>) {
      return ApolloReactHooks.useQuery<MeetingsQuery, MeetingsQueryVariables>(MeetingsDocument, baseOptions);
    }
      export function useMeetingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeetingsQuery, MeetingsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<MeetingsQuery, MeetingsQueryVariables>(MeetingsDocument, baseOptions);
      }
      
export type MeetingsQueryHookResult = ReturnType<typeof useMeetingsQuery>;
export type MeetingsQueryResult = ApolloReactCommon.QueryResult<MeetingsQuery, MeetingsQueryVariables>;
export const GetMeetingDocument = gql`
    query GetMeeting($id: String!) {
  getMeeting(id: $id) {
    id
    title
    date
    content
    location
    url
    isPrivileged
    files {
      id
      filename
      mimetype
      isPrivileged
    }
  }
}
    `;

    export function useGetMeetingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMeetingQuery, GetMeetingQueryVariables>) {
      return ApolloReactHooks.useQuery<GetMeetingQuery, GetMeetingQueryVariables>(GetMeetingDocument, baseOptions);
    }
      export function useGetMeetingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMeetingQuery, GetMeetingQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetMeetingQuery, GetMeetingQueryVariables>(GetMeetingDocument, baseOptions);
      }
      
export type GetMeetingQueryHookResult = ReturnType<typeof useGetMeetingQuery>;
export type GetMeetingQueryResult = ApolloReactCommon.QueryResult<GetMeetingQuery, GetMeetingQueryVariables>;
export const CreateMeetingDocument = gql`
    mutation CreateMeeting($location: String!, $title: String!, $date: String!, $content: String, $url: String) {
  createMeeting(location: $location, title: $title, date: $date, content: $content, url: $url)
}
    `;
export type CreateMeetingMutationFn = ApolloReactCommon.MutationFunction<CreateMeetingMutation, CreateMeetingMutationVariables>;

    export function useCreateMeetingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMeetingMutation, CreateMeetingMutationVariables>) {
      return ApolloReactHooks.useMutation<CreateMeetingMutation, CreateMeetingMutationVariables>(CreateMeetingDocument, baseOptions);
    }
export type CreateMeetingMutationHookResult = ReturnType<typeof useCreateMeetingMutation>;
export type CreateMeetingMutationResult = ApolloReactCommon.MutationResult<CreateMeetingMutation>;
export type CreateMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMeetingMutation, CreateMeetingMutationVariables>;
export const UpdateMeetingDocument = gql`
    mutation UpdateMeeting($location: String, $title: String, $date: String, $content: String, $url: String, $id: String!) {
  updateMeeting(id: $id, location: $location, title: $title, date: $date, content: $content, url: $url)
}
    `;
export type UpdateMeetingMutationFn = ApolloReactCommon.MutationFunction<UpdateMeetingMutation, UpdateMeetingMutationVariables>;

    export function useUpdateMeetingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMeetingMutation, UpdateMeetingMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateMeetingMutation, UpdateMeetingMutationVariables>(UpdateMeetingDocument, baseOptions);
    }
export type UpdateMeetingMutationHookResult = ReturnType<typeof useUpdateMeetingMutation>;
export type UpdateMeetingMutationResult = ApolloReactCommon.MutationResult<UpdateMeetingMutation>;
export type UpdateMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMeetingMutation, UpdateMeetingMutationVariables>;
export const DeleteMeetingDocument = gql`
    mutation DeleteMeeting($id: String!) {
  deleteMeeting(id: $id)
}
    `;
export type DeleteMeetingMutationFn = ApolloReactCommon.MutationFunction<DeleteMeetingMutation, DeleteMeetingMutationVariables>;

    export function useDeleteMeetingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMeetingMutation, DeleteMeetingMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteMeetingMutation, DeleteMeetingMutationVariables>(DeleteMeetingDocument, baseOptions);
    }
export type DeleteMeetingMutationHookResult = ReturnType<typeof useDeleteMeetingMutation>;
export type DeleteMeetingMutationResult = ApolloReactCommon.MutationResult<DeleteMeetingMutation>;
export type DeleteMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMeetingMutation, DeleteMeetingMutationVariables>;
export const AddFileToMeetingDocument = gql`
    mutation AddFileToMeeting($meetingId: String!, $file: Upload!) {
  addFileToMeeting(meetingId: $meetingId, file: $file)
}
    `;
export type AddFileToMeetingMutationFn = ApolloReactCommon.MutationFunction<AddFileToMeetingMutation, AddFileToMeetingMutationVariables>;

    export function useAddFileToMeetingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFileToMeetingMutation, AddFileToMeetingMutationVariables>) {
      return ApolloReactHooks.useMutation<AddFileToMeetingMutation, AddFileToMeetingMutationVariables>(AddFileToMeetingDocument, baseOptions);
    }
export type AddFileToMeetingMutationHookResult = ReturnType<typeof useAddFileToMeetingMutation>;
export type AddFileToMeetingMutationResult = ApolloReactCommon.MutationResult<AddFileToMeetingMutation>;
export type AddFileToMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFileToMeetingMutation, AddFileToMeetingMutationVariables>;
export const FetchTokenDocument = gql`
    query FetchToken($token: String!) {
  fetchToken(token: $token) {
    id
    userId
  }
}
    `;

    export function useFetchTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchTokenQuery, FetchTokenQueryVariables>) {
      return ApolloReactHooks.useQuery<FetchTokenQuery, FetchTokenQueryVariables>(FetchTokenDocument, baseOptions);
    }
      export function useFetchTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchTokenQuery, FetchTokenQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<FetchTokenQuery, FetchTokenQueryVariables>(FetchTokenDocument, baseOptions);
      }
      
export type FetchTokenQueryHookResult = ReturnType<typeof useFetchTokenQuery>;
export type FetchTokenQueryResult = ApolloReactCommon.QueryResult<FetchTokenQuery, FetchTokenQueryVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!, $folderId: String!, $isPrivileged: Boolean) {
  uploadFile(file: $file, folderId: $folderId, isPrivileged: $isPrivileged)
}
    `;
export type UploadFileMutationFn = ApolloReactCommon.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

    export function useUploadFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
      return ApolloReactHooks.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
    }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    firstName
    lastName
    email
    mobile
    isAdmin
    isPrivileged
    logins
    latestLogin
  }
}
    `;

    export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
      return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
    }
      export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
      
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: String!) {
  user(id: $id) {
    id
    firstName
    lastName
    email
    mobile
    isAdmin
    isPrivileged
  }
}
    `;

    export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
      return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
    }
      export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
      
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

    export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
    }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($email: String, $id: String!, $token: String, $password: String, $firstName: String, $lastName: String, $mobile: String, $isAdmin: Boolean, $isPrivileged: Boolean) {
  updateUser(id: $id, token: $token, firstName: $firstName, lastName: $lastName, email: $email, password: $password, mobile: $mobile, isPrivileged: $isPrivileged, isAdmin: $isAdmin)
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

    export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
    }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const RegisterDocument = gql`
    mutation register($email: String!, $password: String!, $firstName: String!, $lastName: String!, $mobile: String, $isAdmin: Boolean, $isPrivileged: Boolean) {
  register(firstName: $firstName, lastName: $lastName, email: $email, password: $password, mobile: $mobile, isPrivileged: $isPrivileged, isAdmin: $isAdmin)
}
    `;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;

    export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
      return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
    }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

    export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
      return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
    }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;