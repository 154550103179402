import { Box, Button, Layer } from "grommet";
import { FormClose, Menu } from "grommet-icons";
import React from "react";
import { SidebarContent } from "./SidebarContent";

interface Props {
  showSidebar: boolean;
  setShowSidebar(value: boolean): void;
}

export const SidebarMobile: React.SFC<Props> = ({
  showSidebar,
  setShowSidebar
}) => {
  if (!showSidebar) {
    return (
      <Box
        background="brand"
        tag="header"
        justify="between"
        align="start"
        direction="row"
        style={{ flexShrink: 0 }}
      >
        <img
          src="/ein.png"
          style={{
            width: "140px",
            alignSelf: "flex-end",
            marginLeft: "2rem",
            marginTop: "1rem",
            marginBottom: "1rem"
          }}
          alt="Expandera i Norr"
        />
        <Button icon={<Menu />} onClick={() => setShowSidebar(!showSidebar)} />
      </Box>
    );
  }
  return (
    <Layer>
      <Box
        background="brand"
        tag="header"
        justify="end"
        align="center"
        direction="row"
      >
        <Button
          icon={<FormClose />}
          onClick={() => setShowSidebar(!showSidebar)}
        />
      </Box>
      <Box fill background="brand" align="center" justify="center">
        <SidebarContent showLogo={false} setShowSidebar={setShowSidebar} />
      </Box>
    </Layer>
  );
};
