import { Box, ResponsiveContext } from "grommet";
import React from "react";
import Moment from "react-moment";
import { ShadowBox } from "../components/layout/ShadowBox";
import { useEventsQuery } from "../generated/graphql";
interface Props {}

const EventLogPage: React.FC<Props> = () => {
  const { data: eventData, loading } = useEventsQuery();

  if (loading) return null;

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box>
            <Box
              direction="row"
              justify="between"
              style={{ marginBottom: "30px" }}
            >
              <h1>Eventlog</h1>
            </Box>

            <Box style={{ flexShrink: 1 }}>
              <ShadowBox>
                <table>
                  <tr>
                    <th>Datum</th>
                    <th>Användare</th>
                    <th>Händelse</th>
                  </tr>
                  {eventData &&
                    eventData.events &&
                    eventData.events.map((event, idx) => {
                      return (
                        <tr>
                          <td style={{ paddingRight: 20 }}>
                            <Moment format="hh:mm DD-MM-YYYY" locale="sv">
                              {event.date}
                            </Moment>
                          </td>
                          <td style={{ paddingRight: 20 }}>
                            {event.user &&
                              `${event.user.firstName} ${event.user.lastName}`}
                          </td>
                          <td>{event.action}</td>
                        </tr>
                      );
                    })}
                </table>
              </ShadowBox>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default EventLogPage;
