import { Box, Button, CheckBox, Form, FormField, TextInput } from "grommet";
import { Save } from "grommet-icons";
import React, { useState } from "react";
import { useCreateFolderMutation } from "../generated/graphql";

interface Props {
  showModal: boolean;
  setShowModal(s: boolean): void;
  companyId: string;
  parentId?: string;
}

export const CreateFolder: React.FC<Props> = ({
  setShowModal,
  companyId,
  parentId
}) => {
  const [privilegedChecked, setPrivilegedChecked] = useState(false);
  const [name, setName] = useState("");

  const [createFolder] = useCreateFolderMutation({
    refetchQueries: [`Company`, "Folder"]
  });
  // const [createArticle] = useCreateArticleMutation({
  //   refetchQueries: ["articles"]
  // });

  return (
    <Box pad="large">
      <h2>Skapa mapp</h2>
      <Form>
        <FormField label="Namn">
          <TextInput
            placeholder="skriv här"
            onChange={({ target }) => {
              setName(target.value);
            }}
          />
        </FormField>
        <CheckBox
          checked={privilegedChecked}
          label="Visa endast för priviligerade användare"
          onChange={() => setPrivilegedChecked(!privilegedChecked)}
        />
        <hr />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            primary
            icon={<Save />}
            label="Spara"
            onClick={async () => {
              try {
                createFolder({
                  variables: {
                    companyId: companyId,
                    parentId: parentId || null,
                    name,
                    isPrivileged: privilegedChecked
                  }
                });
                // createArticle({
                //   variables: {
                //     title: title,
                //     content: htmlContent,
                //     sms: smsChecked,
                //     mail: mailChecked,
                //     isPrivileged: privilegedChecked
                //   }
                // });
                setShowModal(false);
              } catch (error) {
                console.log(error);
              }
            }}
          />
          <Button label="Stäng" onClick={() => setShowModal(false)} />
        </div>
      </Form>
    </Box>
  );
};
