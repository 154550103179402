import CKEditor from "ckeditor4-react";
import {
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Select,
  TextInput
} from "grommet";
import { Save } from "grommet-icons";
import React, { useEffect, useState } from "react";
import {
  useCompaniesQuery,
  useCreateArticleMutation
} from "../generated/graphql";

interface Props {
  showModal: boolean;
  setShowModal(s: boolean): void;
}

export const CreateArticle: React.FC<Props> = ({ setShowModal }) => {
  const [mailChecked, setMailChecked] = useState(false);
  const [smsChecked, setSmsChecked] = useState(false);
  const [privilegedChecked, setPrivilegedChecked] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<any | undefined>();

  useEffect(() => {
    const savedContent = localStorage.getItem("serializedHtmlContent");
    if (savedContent) {
      setContent(atob(savedContent));
      // localStorage.removeItem("serializedHtmlContent");
    }
    setLoading(false);
  }, []);

  const [createArticle] = useCreateArticleMutation({
    refetchQueries: ["Articles"]
  });

  const { data: companiesData } = useCompaniesQuery();
  let companyOptions: any[] = [];
  if (companiesData && companiesData.companies) {
    companiesData.companies.map(c => {
      companyOptions.push(c);
    });
  }

  if (loading || !companiesData) {
    return null;
  }

  return (
    <Box pad="large">
      <h2>Skapa nyhet</h2>
      <Form>
        <FormField label="Rubrik">
          <TextInput
            placeholder="skriv här"
            onChange={({ target }) => {
              setTitle(target.value);
            }}
          />
        </FormField>
        <FormField label="Innehåll">
          <CKEditor
            onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
            data={content}
            onChange={evt => {
              if (evt.editor) {
                const serialisedContent = btoa(evt.editor.getData());
                localStorage.setItem(
                  "serializedHtmlContent",
                  serialisedContent
                );
                setContent(evt.editor.getData());
              }
            }}
            config={{
              height: "40vh",
              language: "sv",
              toolbar: [
                ["Format"],
                ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                ["Link", "Unlink"],
                ["Blockquote"],
                ["Copy", "Cut", "Paste"]
              ]
            }}
            // type="inline"
            style={{
              padding: 20,
              minHeight: "40vh"
            }}
          />
        </FormField>
        {companyOptions && (
          <FormField label="Relaterat bolag">
            <Select
              options={companyOptions}
              placeholder="Valfritt: Välj relaterat bolag"
              labelKey="name"
              valueKey="id"
              value={company ? company.value : undefined}
              onChange={option => {
                setCompany(option);
                console.log(option);
              }}
              selected={company ? company.selected : undefined}
            />
          </FormField>
        )}
        <CheckBox
          checked={mailChecked}
          label="Skicka nyheten som mejl"
          onChange={() => setMailChecked(!mailChecked)}
        />
        <CheckBox
          checked={smsChecked}
          label="Skicka nyheten som sms"
          onChange={() => setSmsChecked(!smsChecked)}
        />
        <CheckBox
          checked={privilegedChecked}
          label="Visa endast för priviligerade användare"
          onChange={() => setPrivilegedChecked(!privilegedChecked)}
        />
        <hr />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            primary
            icon={<Save />}
            label="Spara"
            onClick={async () => {
              const htmlContent = localStorage.getItem("serializedHtmlContent");
              if (htmlContent) {
                try {
                  createArticle({
                    variables: {
                      title: title,
                      content: content,
                      // content: htmlContent,
                      sms: smsChecked,
                      mail: mailChecked,
                      isPrivileged: privilegedChecked,
                      companyId: company ? company.value.id : null
                    }
                  });
                  localStorage.removeItem("serializedHtmlContent");
                  setShowModal(false);
                } catch (error) {}
              }
            }}
          />
          <Button label="Stäng" onClick={() => setShowModal(false)} />
        </div>
        &nbsp;
      </Form>
    </Box>
  );
};
