export const truncateString = (str, length, ending) => {
  if (length == null) {
    length = 30;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const truncateFilename = (str, length) => {
  if (length == null) {
    length = 30;
  }
  const ending = str.substr(str.lastIndexOf(".") + 1);

  if (str.length > length) {
    return str.substring(0, length - ending.length) + "..." + ending;
  } else {
    return str;
  }
};
