import { Button, CheckBox, Form, ResponsiveContext } from "grommet";
import { Send } from "grommet-icons";
import React, { useState } from "react";
import Moment from "react-moment";
import { RouteComponentProps } from "react-router-dom";
import { ShadowBox } from "../components/layout/ShadowBox";
import {
  useArticlesQuery,
  useCreateMailMessageMutation,
} from "../generated/graphql";

interface Props extends RouteComponentProps {}

const MailSendPage: React.FC<Props> = () => {
  const [mailsToSend, setMailsToSend] = useState<string[]>([]);
  const [welcometext, setWelcometext] = useState<string>("");

  const { data } = useArticlesQuery({
    variables: { limit: 20 },
  });
  const [CreateMailMessage] = useCreateMailMessageMutation();

  if (!data) return null;

  const toggleMailsToSend = (str: string) => {
    const currentMailsToSend = mailsToSend.slice();
    if (currentMailsToSend.indexOf(str) !== -1) {
      currentMailsToSend.splice(currentMailsToSend.indexOf(str), 1);
    } else {
      currentMailsToSend.push(str);
    }

    console.log("Current mails to send: ", currentMailsToSend);
    setMailsToSend(currentMailsToSend);
  };

  // const [createArticle] = useCreateArticleMutation({
  //   refetchQueries: ["Articles"],
  // });
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        console.log(size);
        return (
          <>
            <h1>Välkommen</h1>
            {/* <Columns>
              <LeftColumn>
                <h3>Skickade</h3>

                <ShadowBox>lista</ShadowBox>
              </LeftColumn>
              <RightColumn> */}
            <h3>Skapa utskick</h3>
            <p>
              För att skicka ut flera nyheter i samma utskick, välj först de
              nyheter du vill att mejlet ska innehålla och klicka sen på{" "}
              <strong>Skicka</strong>.{" "}
              <em>
                Priviligerade nyheter skickas endast till priviliegerade
                användare.{" "}
              </em>
            </p>

            <ShadowBox>
              {/* <div style={{ float: "right" }}>
                <Button
                  icon={<Send />}
                  label="Skicka"
                  onClick={() => {
                    console.log("Sending");
                  }}
                />
              </div> */}
              <Form>
                <h3>Mailinnehåll</h3>
                <p>En kort text som kommer före listan på artiklar.</p>
                <textarea
                  rows={4}
                  style={{ width: 400 }}
                  onChange={(e) => setWelcometext(e.target.value)}
                ></textarea>
                <h3>Välj nyheter att skicka</h3>

                <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                  {data &&
                    data.articles.length > 0 &&
                    data.articles.map((article, idx) => (
                      <li
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginRight: 10 }}>
                          <CheckBox
                            checked={
                              mailsToSend.indexOf(article.id) === -1
                                ? false
                                : true
                            }
                            onChange={() => toggleMailsToSend(article.id)}
                          />
                        </div>
                        <div>
                          <div>
                            <strong>{article.title}</strong>
                          </div>
                          <Moment style={{ fontSize: 14 }} format="YYYY-MM-DD">
                            {article.date}
                          </Moment>
                          {article.isPrivileged && (
                            <>
                              &nbsp;-&nbsp;<em>Priviligerad artikel.</em>
                            </>
                          )}
                        </div>
                      </li>
                    ))}
                </ul>
                <Button
                  primary
                  icon={<Send />}
                  label="Skicka"
                  onClick={async () => {
                    if (mailsToSend.length > 0) {
                      try {
                        CreateMailMessage({
                          variables: {
                            articles: mailsToSend,
                            welcometext: welcometext,
                          },
                        });
                        // localStorage.removeItem("serializedHtmlContent");
                        // setShowModal(false);
                      } catch (error) {}
                    }
                  }}
                />
              </Form>
            </ShadowBox>
            {/*  && groupedResults.map((g, idx) => <p>{g}</p>)} */}
            {/* </RightColumn>
            </Columns> */}
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default MailSendPage;
