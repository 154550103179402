export function compareByName(a, b) {
  if (a.name.toUpperCase() < b.name.toUpperCase()) {
    return -1;
  }
  if (a.name.toUpperCase() > b.name.toUpperCase()) {
    return 1;
  }
  return 0;
}

export function compareByFilename(a, b) {
  if (a.filename.toUpperCase() < b.filename.toUpperCase()) {
    return -1;
  }
  if (a.filename.toUpperCase() > b.filename.toUpperCase()) {
    return 1;
  }
  return 0;
}
