import React from "react";
import { compareByFilename } from "../../utils/compare";
import { FileRow } from "./FileRow";

interface Props {
  files: any[] | null;
  folderId: string;
}

export const FileList: React.FC<Props> = ({ files = [], folderId }) => {
  if (files === null || !files.length) return null;

  console.log(files);
  return (
    <ul
      style={{
        listStyle: "none",
        margin: 0,

        padding: 0,
        paddingLeft: "10px"
      }}
    >
      {files.sort(compareByFilename).map((file, idx) => (
        <FileRow key={idx} file={file} folderId={folderId} />
      ))}
    </ul>
  );
};
