import { Button } from "grommet";
import React, { useContext } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useLogoutMutation } from "../generated/graphql";

interface Props extends RouteComponentProps {
  showLogo?: boolean;
  setShowSidebar?: (b: boolean) => void;
}

export const SidebarContentWithOutRoutes: React.FC<Props> = ({
  location,
  history,
  setShowSidebar,
  showLogo = true,
}) => {
  const [logout, { client }] = useLogoutMutation();
  const { user, setAccessToken } = useContext(UserContext);

  const { pathname } = location;
  const currentPath = pathname.split("/");
  const activePath = currentPath[1];
  // console.log(pathname);
  // console.log(currentPath[1]);

  const hideSideBar = () => {
    if (typeof setShowSidebar !== "undefined") {
      setShowSidebar(false);
    }
  };

  return (
    <>
      {showLogo && (
        <img
          src="/ein.png"
          style={{
            width: "140px",
            alignSelf: "flex-end",
            marginRight: "2rem",
            marginTop: "2rem",
          }}
          alt="Expandera i Norr"
        />
      )}
      <ul id="sidebarContent">
        <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
          {!activePath && "> "}
          <Link onClick={() => hideSideBar()} to="/">
            Startsida
          </Link>
        </li>
        <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
          {activePath === "e" && "> "}
          <Link onClick={() => hideSideBar()} to="/e">
            Expandera
          </Link>
        </li>
        <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
          {activePath === "pb" && "> "}
          <Link onClick={() => hideSideBar()} to="/pb">
            Portföljbolag
          </Link>
        </li>
        <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
          {activePath === "kb" && "> "}
          <Link onClick={() => hideSideBar()} to="/kb">
            Kandidatbolag
          </Link>
        </li>
        <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
          {activePath === "news" && "> "}
          <Link onClick={() => hideSideBar()} to="/news">
            Nyhetsarkiv
          </Link>
        </li>
        <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
          {activePath === "k" && "> "}
          <Link onClick={() => hideSideBar()} to="/k">
            Kalender
          </Link>
        </li>
        {user && (
          <>
            <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
              <hr />
            </li>
            <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
              {activePath === "profile" && "> "}
              <Link onClick={() => hideSideBar()} to="/profile">
                Min profil
              </Link>
            </li>
          </>
        )}

        {user && user.isAdmin && (
          <>
            <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
              {activePath === "users" && "> "}
              <Link onClick={() => hideSideBar()} to="/users">
                Användare
              </Link>
            </li>
            <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
              {activePath === "el" && "> "}
              <Link onClick={() => hideSideBar()} to="/el">
                Eventlog
              </Link>
            </li>

            <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
              {activePath === "mailsend" && "> "}
              <Link onClick={() => hideSideBar()} to="/mailsend">
                Mailutskick
              </Link>
            </li>
          </>
        )}

        <li style={{ margin: "1rem", padding: 0, listStyle: "none" }}>
          <hr />
        </li>
        <li
          style={{
            margin: "1rem",
            padding: 0,
            listStyle: "none",
            alignSelf: "flex-end",
          }}
        >
          <Button
            primary
            type="button"
            onClick={async () => {
              if (typeof setAccessToken !== "undefined") {
                try {
                  await logout();
                } catch (err) {
                  console.log(err);
                }

                setAccessToken("");
                hideSideBar();
              }
              await client!.resetStore();
            }}
            label={`Logga ut ${user && user.firstName}`}
          />
        </li>
      </ul>
    </>
  );
};

export const SidebarContent = withRouter(SidebarContentWithOutRoutes);
