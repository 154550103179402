import { Box, Button, Layer, ResponsiveContext } from "grommet";
import { Add } from "grommet-icons";
import React, { useContext, useState } from "react";
import { RouteComponentProps } from "react-router";
import { CompanyListing } from "../components/CompanyListing";
import { CreateKCompany } from "../components/CreateKCompany";
import { UserContext } from "../contexts/UserContext";
import { useCompaniesQuery } from "../generated/graphql";

interface Props extends RouteComponentProps {}

export const CandidateCompaniesPage: React.FC<Props> = ({ history }) => {
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(UserContext);
  const { data } = useCompaniesQuery({ variables: { type: "candidate" } });
  if (!data) {
    return null;
  }

  // const openCompany = id => {
  //   history.push(`/b/${id}`);
  // };
  return (
    <>
      <ResponsiveContext.Consumer>
        {size => {
          return (
            <Box>
              <Box
                direction="row"
                justify="between"
                style={{ marginBottom: "30px" }}
              >
                <h1>Kandidatbolag</h1>
                {user && user.isAdmin && (
                  <Button
                    icon={<Add />}
                    label="Lägg till"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                )}
              </Box>
              {data.companies && (
                <Box style={{ flexShrink: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginLeft: `${size !== "small" ? "-20px" : "0"}`,

                      marginRight: `${size !== "small" ? "-20px" : "0"}`,
                      paddingBottom: "100px"
                    }}
                  >
                    <CompanyListing
                      // openCompany={openCompany}
                      companies={data.companies}
                      grid={size !== "small" ? true : false}
                    />
                  </div>
                </Box>
              )}

              {showModal && (
                <Layer
                  onEsc={() => setShowModal(false)}
                  onClickOutside={() => setShowModal(false)}
                >
                  <CreateKCompany
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                </Layer>
              )}
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
    </>
  );
};
