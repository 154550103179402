import React from "react";
import { RouteComponentProps } from "react-router";
import { Company } from "../components/Company";

interface MatchParams {
  cId: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

export const CompanyPage: React.FC<Props> = ({ history, match }) => {
  // console.log(match.params.cId);

  return (
    <>
      <Company companyId={match.params.cId} />
    </>
  );
};
