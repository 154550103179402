import { Box, Button, Layer, ResponsiveContext } from "grommet";
import { Add } from "grommet-icons";
import React, { useContext, useState } from "react";
import { AddFileToArticle } from "../components/AddFileToArticle";
import { CreateArticle } from "../components/CreateArticle";
import { EditArticle } from "../components/EditArticle";
import { NewsListing } from "../components/NewsListing";
import { UserContext } from "../contexts/UserContext";
interface Props {}

export const NewsArchivePage: React.FC<Props> = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editArticleId, setEditArticleId] = useState("");
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [uploadFileToArticleId, setUploadFileToArticleId] = useState("");

  const { user } = useContext(UserContext);

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box>
            <Box
              direction="row"
              justify="between"
              style={{ flexShrink: 0, marginBottom: "30px" }}
            >
              <h1>Nyheter</h1>
              {user && user.isAdmin && (
                <Button
                  icon={<Add />}
                  label="Lägg till"
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              )}
            </Box>
            <Box style={{ flexShrink: 1 }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: `${size !== "small" ? "-20px" : "0"}`,

                  marginRight: `${size !== "small" ? "-20px" : "0"}`,
                  paddingBottom: "100px"
                }}
              >
                <NewsListing
                  grid={size !== "small" ? true : false}
                  setEditArticleId={setEditArticleId}
                  setShowEditModal={setShowEditModal}
                  setUploadFileToArticleId={setUploadFileToArticleId}
                  setShowUploadFileModal={setShowUploadFileModal}
                />
              </div>
            </Box>
            {showModal && (
              <Layer
                onEsc={() => setShowModal(false)}
                onClickOutside={() => setShowModal(false)}
                // full="vertical"
              >
                <Box fill="vertical" overflow="auto">
                  <CreateArticle
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                </Box>
              </Layer>
            )}
            {showEditModal && editArticleId && (
              <Layer
                onEsc={() => setShowEditModal(false)}
                onClickOutside={() => setShowEditModal(false)}
                // full="vertical"
              >
                <Box fill="vertical" overflow="auto">
                  <EditArticle
                    articleId={editArticleId}
                    showModal={showEditModal}
                    setShowModal={setShowEditModal}
                  />
                </Box>
              </Layer>
            )}
            {showUploadFileModal && uploadFileToArticleId && (
              <Layer
                onEsc={() => setShowUploadFileModal(false)}
                onClickOutside={() => setShowUploadFileModal(false)}
                // full="vertical"
              >
                <Box fill="vertical" overflow="auto">
                  <AddFileToArticle
                    articleId={uploadFileToArticleId}
                    showModal={showUploadFileModal}
                    setShowModal={setShowUploadFileModal}
                  />
                </Box>
              </Layer>
            )}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};
