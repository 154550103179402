import { Box, Button, Layer, ResponsiveContext } from "grommet";
import { Add, Edit, Trash } from "grommet-icons";
import React, { useContext, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { c } from "../config";
import { UserContext } from "../contexts/UserContext";
import { useCompanyQuery } from "../generated/graphql";
import { CompanyLinks } from "./CompanyLinks";
import { CreateFile } from "./CreateFile";
import { CreateFolder } from "./CreateFolder";
import { EditCompany } from "./EditCompany";
import { EditFolder } from "./EditFolder";
import { Columns, LeftColumn, RightColumn } from "./layout/Columns";
import { ShadowBox } from "./layout/ShadowBox";
import { FolderList } from "./lists/FolderList";
interface Props {
  companyId: string;
}

export const Company: React.FC<Props> = ({ companyId }) => {
  const { user } = useContext(UserContext);
  const [showFileModal, setShowFileModal] = useState(false);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState("");
  const [currentFolderLevel, setCurrentFolderLevel] = useState(1);

  const { data } = useCompanyQuery({
    variables: { id: companyId }
  });

  if (!data) {
    return <div>loading</div>;
  }
  const { company } = data;
  if (!company) {
    return <div>404</div>;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        console.log(size);
        console.log(company);
        return (
          <>
            <Box direction="row" justify="between">
              <h1>{company.name}</h1>
              {user && user.isAdmin && (
                <div>
                  <>
                    {company.id !== c.REACT_APP_EXPANDERA_ID && (
                      <div
                        style={{ float: "right" }}
                        onClick={() => {
                          if (
                            window.confirm(
                              `Vill du verkligen ta bort ${company.name}?`
                            )
                          ) {
                            // deleteFile({ variables: { id: file.id } });
                          }
                        }}
                      >
                        <Trash color="rgba(0,0,0,0.3)" />
                      </div>
                    )}
                    <div
                      style={{ marginRight: "4px", float: "right" }}
                      onClick={() => {
                        setShowEditCompanyModal(true);
                      }}
                    >
                      <Edit color="rgba(0,0,0,0.3)" />
                    </div>
                  </>
                </div>
              )}
            </Box>

            <Columns>
              <LeftColumn>
                {company.logo && (
                  <>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/file/logos/${company.id}/${company.logo}`}
                      style={{
                        maxWidth: 400,
                        maxHeight: 200,
                        objectFit: "contain"
                      }}
                      alt={company.name}
                    />
                  </>
                )}
                <h3>Beskrivning</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: company.description }}
                />

                {company.articles && company.articles.length > 0 && (
                  <>
                    <h3 style={{ marginTop: "2rem" }}>Nyheter om bolaget</h3>
                    <ul
                      style={{
                        listStyle: "none",
                        margin: 0,
                        padding: 0
                      }}
                    >
                      {company.articles!.map((article, idx) => (
                        <li key={`company-articles-article-${idx}`}>
                          <Moment format="YYYY-MM-DD">{article.date}</Moment>
                          {" - "}
                          <Link to={`/news/${article.id}`}>
                            {article.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                {company.links && (
                  <CompanyLinks links={company.links} companyId={company.id} />
                )}
              </LeftColumn>
              <RightColumn>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <h3>Dokument</h3>
                  {user && user.isAdmin && (
                    <Button
                      icon={<Add />}
                      label="Ny mapp"
                      onClick={() => setShowFolderModal(true)}
                    />
                  )}
                </div>
                <ShadowBox>
                  <FolderList
                    currentFolderLevel={currentFolderLevel}
                    setCurrentFolderLevel={setCurrentFolderLevel}
                    folders={company.folders}
                    setShowFileModal={setShowFileModal}
                    setShowEditFolderModal={setShowEditFolderModal}
                    setCurrentFolderId={setCurrentFolderId}
                  />
                  {/* {company.folders && (
                    <ul
                      style={{
                        listStyle: "none",
                        margin: 0,
                        padding: 0
                      }}
                    >
                      {company.folders.sort(compareByName).map((f, idx) => (
                        <FolderRow
                          key={idx}
                          f={f}
                          setShowFileModal={setShowFileModal}
                          setShowEditFolderModal={setShowEditFolderModal}
                          setCurrentFolderId={setCurrentFolderId}
                        />
                      ))}
                    </ul>
                  )} */}
                </ShadowBox>
              </RightColumn>
            </Columns>

            {showFolderModal && (
              <Layer
                onEsc={() => setShowFolderModal(false)}
                onClickOutside={() => setShowFolderModal(false)}
              >
                <CreateFolder
                  showModal={showFolderModal}
                  setShowModal={setShowFolderModal}
                  companyId={company.id}
                />
              </Layer>
            )}
            {showFileModal && (
              <Layer
                onEsc={() => setShowFileModal(false)}
                onClickOutside={() => setShowFileModal(false)}
              >
                <CreateFile
                  showModal={showFileModal}
                  setShowModal={setShowFileModal}
                  folderId={currentFolderId}
                />
              </Layer>
            )}
            {showEditFolderModal && (
              <Layer
                onEsc={() => setShowEditFolderModal(false)}
                onClickOutside={() => setShowEditFolderModal(false)}
              >
                <EditFolder
                  showModal={showEditFolderModal}
                  setShowModal={setShowEditFolderModal}
                  folderId={currentFolderId}
                />
              </Layer>
            )}
            {showEditCompanyModal && (
              <Layer
                onEsc={() => setShowEditCompanyModal(false)}
                onClickOutside={() => setShowEditCompanyModal(false)}
              >
                <EditCompany
                  showModal={showEditFolderModal}
                  setShowModal={setShowEditCompanyModal}
                  companyId={companyId}
                />
              </Layer>
            )}
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};
