import { Box } from "grommet";
import React from "react";

interface Props {
  grid?: boolean;
  clickAction?: any;
  className?: string;
}

export const ShadowBox: React.FC<Props> = ({
  children,
  grid = false,
  clickAction,
  className
}) => {
  return (
    <Box
      className={className}
      // onPointerDown={clickAction}
      style={{
        width: "100%",
        flexShrink: 1,
        maxWidth: grid ? "calc(50% - 40px)" : "100%",
        borderRadius: "15px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
        padding: "30px",
        margin: `20px ${grid ? "20px" : "0"}`
      }}
    >
      <div>{children}</div>
    </Box>
  );
};
