import { Box, Button, Form } from "grommet";
import { Save } from "grommet-icons";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAddFileToMeetingMutation } from "../generated/graphql";

interface Props {
  showModal: boolean;
  setShowModal(s: boolean): void;
  meetingId: string;
}

export const AddFileToMeeting: React.FC<Props> = ({
  setShowModal,
  meetingId
}) => {
  const [privilegedChecked, setPrivilegedChecked] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [addFileToMeeting] = useAddFileToMeetingMutation({
    refetchQueries: ["Meetings"]
  });
  const onDrop = useCallback(([file]) => {
    setUploadedFile(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });
  // console.log("privilegedChecked: ", privilegedChecked);
  console.log("uploadedFile: ", uploadedFile);
  return (
    <Box pad="large">
      <h2>Ladda upp fil</h2>
      <Form>
        <div
          {...getRootProps()}
          style={{
            border: "2px dotted #efefef",
            padding: "15px",
            textAlign: "center",
            marginBottom: "20px"
          }}
        >
          <input {...getInputProps()} />
          {isDragActive && <p>Släpp här ...</p>}
          {uploadedFile ? (
            uploadedFile.name
          ) : (
            <p>Dra hit en fil eller klicka för att välja</p>
          )}
        </div>
        <hr />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            primary
            icon={<Save />}
            label="Spara"
            onClick={async () => {
              try {
                addFileToMeeting({
                  variables: {
                    meetingId,
                    file: uploadedFile
                  }
                });
                // createFolder({
                //   variables: {
                //     folderId,
                //     name,
                //     isPrivileged: privilegedChecked
                //   }
                // });
                // createArticle({
                //   variables: {
                //     title: title,
                //     content: htmlContent,
                //     sms: smsChecked,
                //     mail: mailChecked,
                //     isPrivileged: privilegedChecked
                //   }
                // });
                setShowModal(false);
              } catch (error) {}
            }}
          />
          <Button label="Stäng" onClick={() => setShowModal(false)} />
        </div>
      </Form>
    </Box>
  );
};
