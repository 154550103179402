type C = {
  REACT_APP_BACKEND_URL: string;
  REACT_APP_FRONTEND_URL: string;
  REACT_APP_REFRESH_TOKEN_URL: string;
  REACT_APP_GRAPHQL_URL: string;
  REACT_APP_EXPANDERA_ID: string;
};
export const c: C = {
  REACT_APP_EXPANDERA_ID: process.env.REACT_APP_EXPANDERA_ID || "",
  REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL || "",
  REACT_APP_FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL || "",
  REACT_APP_REFRESH_TOKEN_URL: `${process.env.REACT_APP_BACKEND_URL ||
    ""}/refresh_token`,
  REACT_APP_GRAPHQL_URL: `${process.env.REACT_APP_BACKEND_URL || ""}/graphql`
};

export const DEFAULT_USER_AUTH = {
  id: null,
  firstname: null,
  lastname: null,
  email: null,
  mobile: null,
  accessToken: null,
  isPrivileged: null,
  isAdmin: null
};
