import { Box, Layer } from "grommet";
import { Upload } from "grommet-icons";
import React, { useContext, useState } from "react";
import Moment from "react-moment";
import { Link, RouteComponentProps } from "react-router-dom";
import { AddFileToArticle } from "../components/AddFileToArticle";
import { Columns, LeftColumn, RightColumn } from "../components/layout/Columns";
import { ShadowBox } from "../components/layout/ShadowBox";
import { FileRow } from "../components/lists/FileRow";
import { UserContext } from "../contexts/UserContext";
import {
  useArticleQuery,
  useArticlesQuery,
  useMeetingsQuery
} from "../generated/graphql";

interface MatchParams {
  articleId: string;
}
interface Props extends RouteComponentProps<MatchParams> { }

export const NewsPage: React.FC<Props> = ({ history, match }) => {
  console.log(match.params.articleId);
  const { user } = useContext(UserContext);
  const { data } = useArticleQuery({
    variables: { id: match.params.articleId }
  });

  const { data: newsData } = useArticlesQuery({
    variables: { limit: 3 }
  });

  const { data: meetingsData } = useMeetingsQuery({
    variables: { limit: 3 }
  });
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [uploadFileToArticleId, setUploadFileToArticleId] = useState(
    match.params.articleId
  );

  console.log(data);

  if (!data) {
    return null;
  }
  const { getArticle } = data;
  return (
    <>
      <Box>
        <h1>{getArticle!.title}</h1>
        <Link to="/">&lt;&lt; Till startsidan</Link>
        <Columns>
          <LeftColumn>
            <h3>
              <Moment format="YYYY-MM-DD">{getArticle!.date}</Moment>
            </h3>
            <div dangerouslySetInnerHTML={{ __html: getArticle!.content.replace(/\\n/g, "") }} />
            {getArticle!.company && (
              <>
                <h3>Relaterat bolag</h3>
                <Link to={`/b/${getArticle!.company.id}`}>
                  {getArticle!.company.name}
                </Link>
              </>
            )}
            {getArticle!.files && (
              <>
                <div>
                  {user &&
                    user.isAdmin &&
                    typeof setUploadFileToArticleId !== "undefined" &&
                    typeof setShowUploadFileModal !== "undefined" && (
                      <div
                        style={{ float: "right", marginRight: "8px" }}
                        onClick={() => {
                          setUploadFileToArticleId(getArticle!.id);
                          setShowUploadFileModal(true);
                        }}
                      >
                        <Upload color="rgba(0,0,0,0.3)" />
                      </div>
                    )}
                  <h3>Bifogade dokument</h3>
                </div>
                <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                  {getArticle!.files.map((file, idx) => {
                    console.log(file);
                    return (
                      <FileRow
                        key={`articles-files-list-file-${idx}`}
                        file={file}
                        folderId={getArticle!.id}
                      />
                    );
                  })}
                </ul>
              </>
            )}
          </LeftColumn>
          <RightColumn>
            {newsData && newsData.articles && (
              <ShadowBox>
                <h3>Senaste Nytt</h3>
                {newsData.articles.map((article, idx) => (
                  <div
                    key={`article-articles-list-${idx}`}
                    style={{
                      marginTop: idx > 0 ? "15px" : 0
                    }}
                  >
                    <Moment format="YYYY-MM-DD">{article.date}</Moment>
                    <br />
                    <Link to={`/news/${article.id}`}>{article.title}</Link>
                  </div>
                ))}
                &nbsp;
                <br />
                <Link to="/news">Fler nyheter</Link>
              </ShadowBox>
            )}

            {meetingsData && (
              <ShadowBox>
                <h3>Närmaste aktiviteterna</h3>
                {meetingsData.meetings.map((meeting, idx) => (
                  <div
                    key={`article-meetings-list-${idx}`}
                    style={{
                      marginTop: idx > 0 ? "15px" : 0
                    }}
                  >
                    {meeting.title}
                    <br />
                    <span style={{ fontSize: "13px" }}>
                      Tid:{" "}
                      <Moment format="YYYY-MM-DD \k\l: HH:mm">
                        {meeting.date}
                      </Moment>
                      <br />
                      Plats: {meeting.location}
                    </span>
                  </div>
                ))}
                &nbsp;
                <br />
                <Link to="/k">Fler aktiviteter</Link>
              </ShadowBox>
            )}
          </RightColumn>
        </Columns>
        {showUploadFileModal && uploadFileToArticleId && (
          <Layer
            onEsc={() => setShowUploadFileModal(false)}
            onClickOutside={() => setShowUploadFileModal(false)}
          // full="vertical"
          >
            <Box fill="vertical" overflow="auto">
              <AddFileToArticle
                articleId={getArticle!.id}
                showModal={showUploadFileModal}
                setShowModal={setShowUploadFileModal}
              />
            </Box>
          </Layer>
        )}
      </Box>
    </>
  );
};
