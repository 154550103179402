import { Box, Collapsible } from "grommet";
import React from "react";
import { SidebarContent } from "./SidebarContent";

interface Props {
  showSidebar?: boolean;
}

export const Sidebar: React.SFC<Props> = ({ showSidebar = true }) => (
  <Collapsible direction="horizontal" open={showSidebar}>
    <Box
      flex
      width="medium"
      background="brand"
      elevation="small"
      align="start"
      justify="start"
      color="white"
    >
      <SidebarContent />
    </Box>
  </Collapsible>
);
