import { ResponsiveContext } from "grommet";
import React from "react";
import styled from "styled-components";
interface Props {}

export { LeftColumn } from "./LeftColumn";
export { RightColumn } from "./RightColumn";
export const Columns: React.FC<Props> = ({ children }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => {
        if (size === "small") {
          return <>{children}</>;
        }
        return <ColumnsWrapper>{children}</ColumnsWrapper>;
      }}
    </ResponsiveContext.Consumer>
  );
};

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
