import { Box, ResponsiveContext } from "grommet";
import React, { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Sidebar } from "./components/Sidebar";
import { SidebarMobile } from "./components/SidebarMobile";
import { UserContext } from "./contexts/UserContext";
import { useUploadFileMutation } from "./generated/graphql";
import { CalendarHistoryPage } from "./pages/CalendarHistoryPage";
import { CalendarPage } from "./pages/CalendarPage";
import { CandidateCompaniesPage } from "./pages/CandidateCompaniesPage";
import { CompanyPage } from "./pages/CompanyPage";
import { EditorPage } from "./pages/EditorPage";
import EventLogPage from "./pages/EventLogPage";
import { ExpanderaPage } from "./pages/ExpanderaPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { HomePage } from "./pages/HomePage";
import { Login } from "./pages/Login";
import MailSendPage from "./pages/MailSendPage";
import { NewsArchivePage } from "./pages/NewsArchivePage";
import { NewsPage } from "./pages/NewsPage";
import { PortoflioCompaniesPage } from "./pages/PortoflioCompaniesPage";
import { ProfilePage } from "./pages/ProfilePage";
import { Register } from "./pages/Register";
import { UsersPage } from "./pages/UsersPage";

export const Routes: React.FC = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { accessToken, user } = useContext(UserContext);

  console.log("USER FROM CONTEXT: ", user);
  if (!accessToken) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/forgot/:token?" component={ForgotPasswordPage} />
          <Route path="/" component={Login} />
        </Switch>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <ResponsiveContext.Consumer>
        {(size) => {
          console.log(size);
          return (
            <>
              <Box
                justify={size === "small" ? "end" : "start"}
                direction={size === "small" ? "column-reverse" : "row"}
                flex
                overflow="auto"
              >
                <Box
                  style={{
                    maxWidth: "960px",
                    minHeight: "100vh",
                    margin: "60px auto",
                    width: "100%",
                    // marginBottom: "100px",
                    padding: "0 30px",
                    paddingBottom: "100px",
                  }}
                >
                  <Switch>
                    <Route exact path="/" component={HomePage} />

                    <Route exact path="/e" component={ExpanderaPage} />
                    <Route
                      exact
                      path="/pb"
                      component={PortoflioCompaniesPage}
                    />
                    <Route
                      exact
                      path="/kb"
                      component={CandidateCompaniesPage}
                    />
                    <Route exact path="/news" component={NewsArchivePage} />
                    <Route exact path="/users" component={UsersPage} />
                    <Route exact path="/b/:cId" component={CompanyPage} />
                    <Route path="/news/:articleId" component={NewsPage} />
                    <Route exact path="/k" component={CalendarPage} />
                    <Route exact path="/h" component={CalendarHistoryPage} />

                    <Route exact path="/register" component={Register} />
                    <Route exact path="/profile" component={ProfilePage} />
                    <Route exact path="/editor" component={EditorPage} />
                    <Route path="/mailsend" component={MailSendPage} />
                    <Route
                      path="/forgot/:token?"
                      component={ForgotPasswordPage}
                    />
                    <Route path="/test" component={UploadTest} />
                    <Route path="/el" component={EventLogPage} />
                  </Switch>
                </Box>
                {size !== "small" ? (
                  <Sidebar />
                ) : (
                  <SidebarMobile
                    showSidebar={showSidebar}
                    setShowSidebar={setShowSidebar}
                  />
                )}
              </Box>
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </BrowserRouter>
  );
};

const UploadTest: React.SFC = () => {
  const [uploadFile] = useUploadFileMutation();
  // const accept = [
  //   "image/png, image/jpg, image/jpeg, text/plain",
  //   "application/pdf"
  // ];
  const onDrop = useCallback(
    ([file]) => {
      console.log("sending: ", file);
      uploadFile({
        variables: {
          folderId: "cbdfa9bd-1179-41ef-9fd1-5209a3beddf3",
          isPrivileged: false,
          file,
        },
      });
    },
    [uploadFile]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </div>
  );
};
