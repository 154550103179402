import { Collapsible, Layer } from "grommet";
import {
  DocumentUpload,
  Down,
  Edit,
  Folder as FolderIcon,
  Trash,
  Up
} from "grommet-icons";
import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
  useDeleteFolderMutation,
  useFolderQuery
} from "../../generated/graphql";
import { CreateFolder } from "../CreateFolder";
import { FileList } from "./FileList";
import { FolderList } from "./FolderList";

interface Props {
  folderId: string;
  setCurrentFolderId: (s: string) => void;
  setShowFileModal: (s: boolean) => void;
  setShowEditFolderModal: (s: boolean) => void;
  currentFolderLevel: number;
  setCurrentFolderLevel: (n: number) => void;
}

export const Folder: React.FC<Props> = ({
  folderId,
  setCurrentFolderId,
  setShowFileModal,
  setShowEditFolderModal,
  currentFolderLevel,
  setCurrentFolderLevel
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { data } = useFolderQuery({
    variables: { id: folderId }
    // fetchPolicy: "network-only"
  });

  const [deleteFolder] = useDeleteFolderMutation({
    refetchQueries: ["Company", "Folder"]
  });

  const [showFolderModal, setShowFolderModal] = useState(false);

  if (!data) {
    return null;
  }

  const { folder } = data;
  console.log(`FOLDER: ${folder.name}`, folder);

  if (folder.isPrivileged && user && !(user.isPrivileged || user.isAdmin)) {
    return null;
  }
  return (
    <li style={{ marginBottom: "16px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "8px"
        }}
      >
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{ marginRight: "4px" }}
        >
          {isOpen ? <Up /> : <Down />}
        </div>
        {user && user.isAdmin && (
          <div
            onClick={() => {
              setCurrentFolderId(folder.id);
              setShowFileModal(true);
            }}
            style={{ marginRight: "4px" }}
          >
            <DocumentUpload />
          </div>
        )}
        <div style={{ flexGrow: 1 }}>
          <strong>{folder.name}</strong>
        </div>
        {user && user.isAdmin && (
          <>
            {currentFolderLevel < 4 && (
              <div
                style={{ marginRight: "4px", float: "right" }}
                onClick={() => {
                  setShowFolderModal(true);
                }}
              >
                <FolderIcon color="rgba(0,0,0,0.3)" />
              </div>
            )}
            <div
              style={{ marginRight: "4px", float: "right" }}
              onClick={() => {
                setCurrentFolderId(folder.id);
                setShowEditFolderModal(true);
              }}
            >
              <Edit color="rgba(0,0,0,0.3)" />
            </div>
            <div
              style={{ float: "right" }}
              onClick={() => {
                if (
                  window.confirm(`Vill du verkligen ta bort ${folder.name}?`)
                ) {
                  deleteFolder({ variables: { id: folder.id } });
                }
              }}
            >
              <Trash color="rgba(0,0,0,0.3)" />
            </div>
          </>
        )}
      </div>
      <Collapsible open={isOpen} direction="vertical">
        {folder.files && <FileList files={folder.files} folderId={folder.id} />}
        {folder.children!.length > 0 && (
          <div style={{ marginLeft: 10, marginTop: 20 }}>
            <FolderList
              folders={folder.children}
              setShowFileModal={setShowFileModal}
              setShowEditFolderModal={setShowEditFolderModal}
              setCurrentFolderId={setCurrentFolderId}
              currentFolderLevel={currentFolderLevel}
              setCurrentFolderLevel={setCurrentFolderLevel}
            />
          </div>
        )}
      </Collapsible>
      {/* CREATE SUBFOLDER */}
      {showFolderModal && (
        <Layer
          onEsc={() => setShowFolderModal(false)}
          onClickOutside={() => setShowFolderModal(false)}
        >
          <CreateFolder
            showModal={showFolderModal}
            setShowModal={setShowFolderModal}
            companyId="abc"
            parentId={folder.id}
          />
        </Layer>
      )}
    </li>
  );
};
