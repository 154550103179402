import { Box, Button, FormField, TextInput } from "grommet";
import { Save, Send } from "grommet-icons";
import React, { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  useFetchTokenQuery,
  useForgotPasswordMutation,
  useUpdateUserMutation
} from "../generated/graphql";

interface MatchParams {
  token: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

export const ForgotPasswordPage: React.FC<Props> = ({ history, match }) => {
  console.log("HELLO!");
  const token = match.params.token;

  if (!token) {
    return <ForgotPassword />;
    // history.push("/");
  }

  return <ForgotPasswordForm token={token} />;
};

// *******************
// SUBMIT EMAIL FORM
// *******************

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState();

  const [forgotPassword] = useForgotPasswordMutation();

  return (
    <Box
      fill
      justify="center"
      align="center"
      style={{
        backgroundImage: "url('/background.jpg'",
        backgroundSize: "cover"
      }}
    >
      <div
        style={{
          padding: 40,
          margin: 10,
          backgroundColor: "#fff",
          borderRadius: 10
        }}
      >
        <Box>
          <h3>Glömt lösenord</h3>

          <Box>
            <p>
              Fyll i din epost så skickar vi en länk där du kan byta lösenord.
            </p>
            <FormField label="Din epostadress">
              <TextInput onChange={e => setEmail(e.target.value)} />
            </FormField>

            <hr />
            <Button
              icon={<Send />}
              label="Skicka"
              alignSelf="start"
              style={{
                float: "left"
              }}
              onClick={async () => {
                if (!email) {
                  setMessage(<p>Du måste fylla i en epostadress</p>);
                  return false;
                }

                try {
                  const success = await forgotPassword({
                    variables: {
                      email
                    }
                  });

                  if (success) {
                    setMessage(
                      <>
                        <p>Vi har skickat en länk till din epost.</p>
                      </>
                    );
                  }
                } catch (error) {
                  setMessage(`Ett fel uppstod: ${error}`);
                }

                console.log("SUBMITTED!");
              }}
            />
            <div
              style={{
                marginTop: 10,
                textAlign: "right"
              }}
            >
              <Link to="/">Logga in</Link>
            </div>
            {message && (
              <div
                style={{
                  border: "1px solid green",
                  padding: 10,
                  marginTop: 20
                }}
              >
                {message}
              </div>
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};

// *******************
// FORGOT PASSWORD FORM
// *******************

interface FPFProps {
  token: string;
}
const ForgotPasswordForm: React.FC<FPFProps> = ({ token = "" }) => {
  const { data: tokenData } = useFetchTokenQuery({
    variables: {
      token
    },
    fetchPolicy: "network-only"
  });

  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [message, setMessage] = useState();

  const [updateUser] = useUpdateUserMutation({
    refetchQueries: ["Users"]
  });

  if (!tokenData) {
    return null;
  }

  console.log(tokenData);

  if (!tokenData.fetchToken) {
    return (
      <Box
        fill
        justify="center"
        align="center"
        style={{
          backgroundImage: "url('/background.jpg'",
          backgroundSize: "cover"
        }}
      >
        <div
          style={{
            padding: 40,
            margin: 10,
            backgroundColor: "#fff",
            borderRadius: 10
          }}
        >
          <h3>Ooops!</h3>
          <p>Vi kunde inte hitta någon giltig byta-lösenordstoken.</p>
          <a href="/forgot">
            Försök fylla i din epost igen och klicka sen på länken i mejlet du
            får.
          </a>
        </div>
      </Box>
    );
  }

  return (
    <Box
      fill
      justify="center"
      align="center"
      style={{
        backgroundImage: "url('/background.jpg'",
        backgroundSize: "cover"
      }}
    >
      <div
        style={{
          padding: 40,
          margin: 10,
          backgroundColor: "#fff",
          borderRadius: 10
        }}
      >
        <Box>
          <h3>Uppdatera lösenord</h3>

          <Box>
            <p>Fyll i ditt önskade lösenord.</p>
            <FormField label="Nytt Lösenord">
              <TextInput
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </FormField>
            <FormField label="Repetera Lösenord">
              <TextInput
                type="password"
                value={passwordCheck}
                onChange={e => setPasswordCheck(e.target.value)}
              />
            </FormField>

            <hr />
            <Button
              icon={<Save />}
              label="Spara"
              alignSelf="start"
              style={{
                float: "left"
              }}
              onClick={async () => {
                if (password && password !== passwordCheck) {
                  alert("Lösenorden stämmer inte överens, prova igen");
                  setPassword("");
                  setPasswordCheck("");
                  return false;
                }

                try {
                  const success = await updateUser({
                    variables: {
                      id: tokenData.fetchToken.userId,
                      token: token,
                      password: password
                    }
                  });

                  if (success) {
                    setMessage(
                      <>
                        <p>Din lösenord uppdaterades utan problem.</p>
                        <Link to="/">Tillbaka till startsidan</Link>
                      </>
                    );
                  }
                } catch (error) {
                  setMessage(`Ett fel uppstod: ${error}`);
                }

                console.log("SUBMITTED!");
              }}
            />
            {message && (
              <div
                style={{
                  border: "1px solid green",
                  padding: 10,
                  marginTop: 20
                }}
              >
                {message}
              </div>
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};
