import { Box, Button, CheckBox, Form, FormField, TextInput } from "grommet";
import { Save } from "grommet-icons";
import React, { useEffect, useState } from "react";
import {
  useGetUserQuery,
  useRegisterMutation,
  useUpdateUserMutation
} from "../generated/graphql";

interface Props {
  showModal: boolean;
  setShowModal(s: boolean): void;
  setShowEditModal?(s: boolean): void;
  userId?: string;
}

export const CreateUser: React.FC<Props> = ({
  setShowModal,
  setShowEditModal,
  userId = ""
}) => {
  // const [loading, setLoading] = useState(userId ? true : false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPrivileged, setIsPrivileged] = useState(false);

  const { data } = useGetUserQuery({ variables: { id: userId } });

  useEffect(() => {
    if (data && data.user) {
      setFirstName(data.user.firstName);
      setLastName(data.user.lastName);
      setEmail(data.user.email);
      setMobile(data.user.mobile);
      setIsAdmin(data.user.isAdmin);
      setIsPrivileged(data.user.isPrivileged);
    }
  }, [data, userId]);
  // if (userId) {
  //   if (data && data.user) {
  //   // setLoading(false);
  //   // console.log("FETCHING USER!");
  // }

  const [register] = useRegisterMutation({ refetchQueries: ["Users"] });
  const [updateUser] = useUpdateUserMutation({
    refetchQueries: ["Users"]
  });

  // if (loading) {
  //   return null;
  // }

  const closeModal = () => {
    if (typeof setShowEditModal !== "undefined") {
      setShowEditModal(false);
    }
    setShowModal(false);
  };
  return (
    <Box pad="large">
      <h2>Skapa användare</h2>
      <Form>
        <FormField label="Förnamn">
          <TextInput
            value={firstName}
            placeholder="Skriv här"
            onChange={({ target }) => {
              setFirstName(target.value);
            }}
          />
        </FormField>
        <FormField label="Efternamn">
          <TextInput
            value={lastName}
            placeholder="Skriv här"
            onChange={({ target }) => {
              setLastName(target.value);
            }}
          />
        </FormField>
        <FormField label="Epost">
          <TextInput
            value={email}
            placeholder="Skriv här"
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </FormField>
        <FormField label="Mobil">
          <TextInput
            value={mobile}
            placeholder="Skriv här"
            onChange={({ target }) => {
              setMobile(target.value);
            }}
          />
        </FormField>
        <FormField label="Lösenord">
          <TextInput
            value={password}
            placeholder="ändra lösenord"
            onChange={({ target }) => {
              setPassword(target.value);
            }}
          />
        </FormField>
        <CheckBox
          checked={isPrivileged}
          label="Priviligerad användare"
          onChange={() => setIsPrivileged(!isPrivileged)}
        />

        <CheckBox
          checked={isAdmin}
          label="Administratör"
          onChange={() => setIsAdmin(!isAdmin)}
        />
        <hr />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            primary
            icon={<Save />}
            label="Spara"
            onClick={async e => {
              e.preventDefault();
              console.log("form submitted");

              let response;
              try {
                if (userId) {
                  response = await updateUser({
                    variables: {
                      id: userId,
                      email,
                      password,
                      firstName,
                      lastName,
                      mobile,
                      isAdmin,
                      isPrivileged
                    }
                  });
                } else {
                  response = await register({
                    variables: {
                      email,
                      password,
                      firstName,
                      lastName,
                      mobile,
                      isAdmin,
                      isPrivileged
                    }
                  });
                }
                if (response) {
                  closeModal();
                }
                // console.log(response);
              } catch (err) {
                console.log(err);
              }
            }}
          />
          <Button label="Stäng" onClick={() => closeModal()} />
        </div>
      </Form>
    </Box>
  );
};
