import { Box, Button, Layer, ResponsiveContext } from "grommet";
import { Add, Download } from "grommet-icons";
import React, { useContext, useState } from "react";
import { CreateUser } from "../components/CreateUser";
import { UsersListing } from "../components/UsersListing";
import { UserContext } from "../contexts/UserContext";
import { RouteComponentProps } from "react-router";
import { c } from "../config";

interface Props extends RouteComponentProps {}

export const UsersPage: React.FC<Props> = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editUserId, setEditUserId] = useState("");
  const { user } = useContext(UserContext);

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box>
            <Box
              direction="row"
              justify="between"
              style={{ marginBottom: "30px" }}
            >
              <h1>Användare</h1>
              {user && user.isAdmin && (
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <Button
                    icon={<Download />}
                    label="Ladda ner"
                    onClick={() => {
                      window.location.href = `${c.REACT_APP_BACKEND_URL}/to_excel`;
                    }}
                  />
                  &nbsp;
                  <Button
                    icon={<Add />}
                    label="Lägg till"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                </div>
              )}
            </Box>

            <Box style={{ flexShrink: 1 }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: `${size !== "small" ? "-20px" : "0"}`,

                  marginRight: `${size !== "small" ? "-20px" : "0"}`,
                  paddingBottom: "100px"
                }}
              >
                <UsersListing
                  grid={size !== "small" ? true : false}
                  setEditUserId={setEditUserId}
                  setShowEditModal={setShowEditModal}
                />
              </div>
            </Box>
            {showModal && (
              <Layer
                onEsc={() => setShowModal(false)}
                onClickOutside={() => setShowModal(false)}
              >
                <CreateUser showModal={showModal} setShowModal={setShowModal} />
              </Layer>
            )}
            {showEditModal && (
              <Layer
                onEsc={() => setShowEditModal(false)}
                onClickOutside={() => setShowEditModal(false)}
              >
                <CreateUser
                  showModal={showModal}
                  setShowModal={setShowModal}
                  setShowEditModal={setShowEditModal}
                  userId={editUserId}
                />
              </Layer>
            )}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};
