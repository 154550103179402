import { Box } from "grommet";
import { Checkmark, Edit, Trash } from "grommet-icons";
import "moment-timezone";
import React, { useContext } from "react";
import Moment from "react-moment";
import { UserContext } from "../contexts/UserContext";
import { useDeleteUserMutation, useUsersQuery } from "../generated/graphql";
import { ShadowBox } from "./layout/ShadowBox";
interface Props {
  limit?: number;
  grid?: boolean;
  setShowEditModal: (b: boolean) => void;
  setEditUserId: (b: string) => void;
}

export const UsersListing: React.FC<Props> = ({
  limit,
  grid = false,
  setEditUserId,
  setShowEditModal
}) => {
  const { user } = useContext(UserContext);
  let isPrivileged = true;
  if (user && (user.isPrivileged || user.isAdmin)) {
    isPrivileged = false;
  }
  console.log(isPrivileged);
  const [deleteUser] = useDeleteUserMutation({
    refetchQueries: ["Users"]
  });
  const { data } = useUsersQuery();
  let users: JSX.Element[] | null = null;
  if (data && data.users.length > 0) {
    console.log(data.users);
    data.users.sort((a, b) => {
      return a.firstName.toLowerCase() < b.firstName.toLowerCase()
        ? -1
        : a.firstName.toLowerCase() > b.firstName.toLowerCase()
        ? 1
        : 0;
    });
    users = data!.users.map((u, idx) => {
      return (
        <ShadowBox key={`${idx}`} grid={grid}>
          <Box direction="row" justify="between">
            <h3>{u.firstName + " " + u.lastName}</h3>
            {user && user.isAdmin && (
              <div>
                <div
                  onClick={() => {
                    setEditUserId(u.id);
                    setShowEditModal(true);
                  }}
                >
                  <Edit />
                </div>
                <div
                  onClick={() => {
                    if (
                      window.confirm(
                        `Vill du verkligen ta bort ${u.firstName} ${u.lastName}?`
                      )
                    ) {
                      deleteUser({ variables: { id: u.id } });
                    }
                  }}
                >
                  <Trash />
                </div>
              </div>
            )}
          </Box>
          {u.email}
          <br />
          {u.mobile}
          <p>
            Antal inloggningar: {u.logins}
            <br />
            {u.latestLogin && (
              <>
                Senaste inloggningen:{" "}
                <Moment format="DD-MM-YYYY hh:mm" locale="sv">
                  {u.latestLogin}
                </Moment>
              </>
            )}
          </p>
          {(!!u.isPrivileged || !!u.isAdmin) && (
            <Box direction="row" fill style={{ marginTop: 10 }}>
              <Box fill>
                {u.isPrivileged && (
                  <Box direction="row">
                    <Checkmark color="brand" style={{ marginRight: 10 }} />
                    Priviligerad
                  </Box>
                )}
              </Box>
              <Box fill>
                {u.isAdmin && (
                  <Box direction="row">
                    <Checkmark color="brand" style={{ marginRight: 10 }} />
                    Administratör
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </ShadowBox>
      );
    });
  }
  // console.log(users);

  if (data && data.users.length < 1) {
    return <div>Inga artiklar hittades</div>;
  }
  return <>{users}</>;
};
