import CKEditor from "ckeditor4-react";
import { Box, TextInput } from "grommet";
import React, { useEffect, useState } from "react";

interface Props {}

export const EditorPage: React.FC<Props> = () => {
  const [value, setValue] = useState("<p>Text...</p>");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const savedContent = localStorage.getItem("serializedHtmlContent");
    if (savedContent) {
      setValue(atob(savedContent));
      localStorage.removeItem("serializedHtmlContent");
    }
  }, []);

  return (
    <>
      <Box style={{ backgroundColor: "red", flex: 1 }}>
        <h1>The Editor</h1>
        <label>
          title
          <TextInput
            name="title"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </label>
        <CKEditor
          onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
          data={value}
          onChange={e => {
            setValue(e.editor.getData());
          }}
          config={{
            height: "40vh",
            language: "sv",
            toolbar: [
              ["Format"],
              ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
              ["Link", "Unlink"],
              ["Blockquote"],
              ["Copy", "Cut", "Paste"]
            ]
          }}
          // type="inline"
          style={{
            padding: 20,
            minHeight: "40vh"
          }}
        />
        <button
          onPointerDown={async () => {
            const serialisedContent = btoa(value);
            await localStorage.setItem(
              "serializedHtmlContent",
              serialisedContent
            );

            console.log(btoa(value));
          }}
        ></button>
        <h3>Preview:</h3>
        <div dangerouslySetInnerHTML={{ __html: value }}></div>
      </Box>
    </>
  );
};
