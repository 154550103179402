import { Box } from "grommet";
import { Trash } from "grommet-icons";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useDeleteCompanyMutation } from "../generated/graphql";
import { truncateString } from "../utils/truncateString";
import { ShadowBox } from "./layout/ShadowBox";

interface Props {
  companies?: any[];
  grid?: boolean;
  openCompany?: any;
}

export const CompanyListing: React.FC<Props> = ({
  companies,
  grid = false,
  openCompany
}) => {
  const { user } = useContext(UserContext);
  const [deleteCompany] = useDeleteCompanyMutation({
    refetchQueries: ["Companies"]
  });
  if (!companies) {
    return <></>;
  }
  if (companies.length < 1) {
    return <> Inga bolag hittades </>;
  }
  return (
    <>
      {companies.map((c, idx) => (
        <ShadowBox
          key={`${idx}`}
          grid={grid}
          className="company-listting"
          // clickAction={() => openCompany(c.id)}
        >
          <Link to={`/b/${c.id}`}>
            <Box
              direction="row"
              justify="between"
              align="start"
              style={{ width: "100%" }}
            >
              <div
                style={{
                  height: 150,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {c.logo && (
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/file/logos/${c.id}/${c.logo}`}
                    style={{
                      maxWidth: 200,
                      maxHeight: 200,
                      objectFit: "contain"
                    }}
                    alt={c.name}
                  />
                )}
              </div>
              {user && user.isAdmin && (
                <div>
                  <div
                    onClick={e => {
                      e.preventDefault();
                      if (
                        window.confirm(`Vill du verkligen ta bort ${c.name}?`)
                      ) {
                        deleteCompany({ variables: { id: c.id } });
                      }
                    }}
                  >
                    <Trash />
                  </div>
                </div>
              )}
            </Box>

            <h3>{c.name}</h3>
            <div
              style={{ marginBottom: 20 }}
              dangerouslySetInnerHTML={{
                __html: truncateString(
                  c.description.replace(/<[^>]*>?/gm, ""),
                  150,
                  "..."
                )
              }}
            ></div>
            {/* <Link to={`/b/${c.id}`}>Visa bolag</Link> */}
          </Link>
        </ShadowBox>
      ))}
    </>
  );
};
