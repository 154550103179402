import { Grommet } from "grommet";
import "moment/locale/sv";
import React, { useContext, useEffect, useState } from "react";
import { c } from "./config";
import { UserContext } from "./contexts/UserContext";
import { Routes } from "./Routes";

interface Props {}

export const theme = {
  global: {
    colors: {
      brand: "#3E8B35",
      "accent-1": "#fff"
    },
    font: {
      family: "Gudea",
      size: "16px",
      height: "22px"
    }
  }
};

export const App: React.FC<Props> = () => {
  const [localLoading, setLocalLoading] = useState(true);
  const { setAccessToken, setUser } = useContext(UserContext);

  useEffect(() => {
    // console.log(test);
    fetch(c.REACT_APP_REFRESH_TOKEN_URL, {
      method: "POST",
      credentials: "include"
    }).then(async x => {
      const tokendata = await x.json();

      if (tokendata.accessToken && typeof setAccessToken !== "undefined") {
        setAccessToken(tokendata.accessToken);
      }
      if (tokendata.user && typeof setUser !== "undefined") {
        setUser(tokendata.user);
      }
      setLocalLoading(false);
    });
  }, [setUser, setAccessToken]);

  if (localLoading) {
    return null;
  }

  return (
    <Grommet
      theme={theme}
      full
      // style={{ minWidth: "100vw", minHeight: "100vh" }}
    >
      <Routes />
    </Grommet>
  );
};
