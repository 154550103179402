import { Box, Button, FormField, TextInput } from "grommet";
import { Save } from "grommet-icons";
import React, { useContext, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useGetUserQuery, useUpdateUserMutation } from "../generated/graphql";

interface MatchParams {
  articleId: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

export const ProfilePage: React.FC<Props> = ({ history }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const [message, setMessage] = useState();

  const { user } = useContext(UserContext);

  const [updateUser] = useUpdateUserMutation({
    refetchQueries: ["Users", "GetUser"]
  });

  const { data } = useGetUserQuery({
    variables: { id: user!.id },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (data && data.user) {
      const userData = data.user;
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setEmail(userData.email);
      setMobile(userData.mobile);
    }
  }, [data]);

  // console.log(data);

  if (!data) {
    return null;
  }
  return (
    <>
      <Box>
        <h1>Min profil</h1>

        <Box>
          <p>Här kan du uppdatera din användarprofil.</p>
          <h3>Nuvarande användarinformation</h3>
          <FormField label="Förnamn">
            <TextInput
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </FormField>
          <FormField label="Efternamn">
            <TextInput
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </FormField>
          <FormField label="Epost">
            <TextInput value={email} onChange={e => setEmail(e.target.value)} />
          </FormField>
          <FormField label="Mobilnummer">
            <TextInput
              value={mobile}
              onChange={e => setMobile(e.target.value)}
            />
          </FormField>
          <div
            style={{
              marginTop: "20px",
              marginLeft: "12px"
            }}
          >
            Lämna lösenordet blank om du inte vill ändra det.
          </div>
          <FormField label="Nytt Lösenord">
            <TextInput
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormField>
          <FormField label="Repetera Lösenord">
            <TextInput
              type="password"
              value={passwordCheck}
              onChange={e => setPasswordCheck(e.target.value)}
            />
          </FormField>

          <hr />
          <Button
            icon={<Save />}
            label="Spara"
            alignSelf="start"
            style={{
              float: "left"
            }}
            onClick={async () => {
              if (password && password !== passwordCheck) {
                alert("Lösenorden stämmer inte överens, prova igen");
                setPassword("");
                setPasswordCheck("");
                return false;
              }

              try {
                const success = await updateUser({
                  variables: {
                    id: user!.id,
                    firstName,
                    lastName,
                    email,
                    mobile,
                    password: password && password
                  }
                });

                if (success) {
                  setMessage(
                    <>
                      <p>Din profil uppdaterades utan problem.</p>
                      <Link to="/">Tillbaka till startsidan</Link>
                    </>
                  );
                }
              } catch (error) {
                setMessage(`Ett fel uppstod: ${error}`);
              }

              console.log("SUBMITTED!");
            }}
          />
          {message && (
            <div
              style={{
                border: "1px solid green",
                padding: 10,
                marginTop: 20
              }}
            >
              {message}
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};
