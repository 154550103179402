import { Box, Button, Layer, ResponsiveContext } from "grommet";
import { Add, Calendar, Edit, Trash, Upload } from "grommet-icons";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useState } from "react";
import Moment from "react-moment";
import { RouteComponentProps } from "react-router-dom";
import { AddFileToMeeting } from "../components/AddFileToMeeting";
import { CreateMeeting } from "../components/CreateMeeting";
import { EditMeeting } from "../components/EditMeeting";
import { ShadowBox } from "../components/layout/ShadowBox";
import { FileRow } from "../components/lists/FileRow";
import { UserContext } from "../contexts/UserContext";
import {
  useDeleteMeetingMutation,
  useMeetingsQuery
} from "../generated/graphql";

interface Props extends RouteComponentProps { }

export const CalendarHistoryPage: React.FC<Props> = ({ history }) => {
  const { user } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [editMeetingId, setEditMeetingId] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);

  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [uploadFileToMeetingId, setUploadFileToMeetingId] = useState("");

  const { data: meetingData, loading } = useMeetingsQuery({
    variables: { historic: true }
  });
  const [deleteMeeting] = useDeleteMeetingMutation({
    refetchQueries: ["Meetings"]
  });

  if (loading) {
    return null;
  }

  // order by monts

  let groupedMeetingList;
  let groupedResults;
  if (
    meetingData &&
    typeof meetingData.meetings !== "undefined" &&
    meetingData.meetings.length
  ) {
    groupedResults = _.groupBy(meetingData.meetings, meeting => {
      console.log(meeting);
      console.log(new Date(meeting["date"]));
      return moment(new Date(meeting["date"]))
        .startOf("month")
        .toDate();
    });
    // const orderedGroupedResults = {};
    // Object.keys(groupedResults)
    //   .sort(function(a, b) {
    //     a = +new Date(a);
    //     b = +new Date(b);
    //     return a > b ? -1 : a < b ? 1 : 0;
    //   })
    //   .forEach(function(key) {
    //     orderedGroupedResults[key] = groupedResults[key];
    //   });
    // groupedMeetingList =
    const groupedAndSortedResults = Object.keys(groupedResults).sort(function (
      a,
      b
    ) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return +new Date(a) + +new Date(b);
    });
    // console.log("GROUPED: ", groupedAndSortedResults);
    groupedMeetingList = groupedAndSortedResults.map((o, idx) => {
      console.log(groupedResults[o]);
      return (
        <div key={`grouped-meeting-list-month-${idx}`}>
          <ShadowBox>
            <h4
              style={{
                textTransform: "uppercase"
              }}
            >
              <Moment format="MMMM" locale="sv">
                {o}
              </Moment>
            </h4>
            {groupedResults[o].map((meeting, idx) => {
              return (
                <div
                  key={`meetinglist-meeting-${idx}`}
                  style={{
                    marginBottom: 20,
                    paddingTop: idx > 0 ? 20 : 0,
                    borderTop: idx > 0 ? "1px solid #ededed" : 0
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <div
                        style={{
                          color: "#398722",
                          fontSize: 18,
                          fontWeight: "bold",
                          border: "1px solid #398722",
                          padding: 8,
                          borderRadius: 100,
                          marginRight: 10,
                          marginTop: 8
                        }}
                      >
                        <Moment format="DD">{meeting.date}</Moment>
                      </div>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <strong>{meeting.title}</strong>

                        {user && user.isAdmin && (
                          <div style={{ display: "flex" }}>
                            <div
                              style={{ marginRight: "8px" }}
                              onClick={() => {
                                setUploadFileToMeetingId(meeting.id);
                                setShowUploadFileModal(true);
                              }}
                            >
                              <Upload color="rgba(0,0,0,0.3)" />
                            </div>
                            <div
                              style={{ marginRight: "8px" }}
                              onClick={() => {
                                setEditMeetingId(meeting.id);
                                setShowEditModal(true);
                              }}
                            >
                              <Edit color="rgba(0,0,0,0.3)" />
                            </div>

                            <div
                              onClick={() => {
                                if (
                                  window.confirm(
                                    `Vill du verkligen ta bort ${meeting.title}?`
                                  )
                                ) {
                                  deleteMeeting({
                                    variables: { id: meeting.id }
                                  });
                                }
                              }}
                            >
                              <Trash color="rgba(0,0,0,0.3)" />
                            </div>
                          </div>
                        )}
                      </div>

                      <div>
                        <div style={{ marginBottom: meeting.content ? 0 : 20 }}>
                          <strong>Tid:</strong>{" "}
                          <Moment format="HH:mm" locale="sv">
                            {meeting.date}
                          </Moment>
                          {meeting.location && (
                            <>
                              {","} <strong>Plats</strong>: {meeting.location}
                            </>
                          )}
                        </div>
                        <div>
                          {meeting.content && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: meeting.content.replace(/\\n/g, "")
                              }}
                            />
                          )}
                          {meeting.url && (
                            <div>
                              <div>
                                <strong>Bifogad länk</strong>
                              </div>
                              <a
                                href={meeting.url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Öppna länk
                              </a>
                            </div>
                          )}
                          {meeting.files.length > 0 && (
                            <>
                              <div style={{ marginTop: meeting.url ? 20 : 0 }}>
                                <strong>Bifogade dokument</strong>
                              </div>
                              <ul
                                style={{
                                  listStyle: "none",
                                  margin: 0,
                                  padding: 0
                                }}
                              >
                                {meeting.files.map((file, idx) => {
                                  console.log(file);
                                  return (
                                    <FileRow
                                      key={`articles-files-list-file-${idx}`}
                                      file={file}
                                      folderId={meeting.id}
                                    />
                                  );
                                })}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </ShadowBox>
        </div>
      );
    });
  }
  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box>
            <Box
              direction="row"
              justify="between"
              style={{ flexShrink: 0, marginBottom: "30px" }}
            >
              <h1>Kalenderhistorik</h1>
              <Box direction="row">
                <Button
                  icon={<Calendar />}
                  label="Kalender"
                  onClick={() => {
                    history.push(`/k`);
                  }}
                />
                {user && user.isAdmin && (
                  <Button
                    style={{ marginLeft: 10 }}
                    icon={<Add />}
                    label="Lägg till"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box style={{ flexShrink: 1 }}>
              {/* <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: `${size !== "small" ? "-20px" : "0"}`,

                  marginRight: `${size !== "small" ? "-20px" : "0"}`,
                  paddingBottom: "100px"
                }}
              > */}
              {!meetingData && !meetingData!.meetings && (
                <div>Inga kalenderaktiviteter hittades</div>
              )}
              {groupedMeetingList && groupedMeetingList}
              {/* </div> */}
            </Box>
            {showModal && (
              <Layer
                onEsc={() => setShowModal(false)}
                onClickOutside={() => setShowModal(false)}
              // full="vertical"
              >
                <Box fill="vertical" overflow="auto">
                  <CreateMeeting
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                  {/* <CreateArticle
                    showModal={showModal}
                    setShowModal={setShowModal}
                  /> */}
                </Box>
              </Layer>
            )}
            {showEditModal && editMeetingId && (
              <Layer
                onEsc={() => setShowEditModal(false)}
                onClickOutside={() => setShowEditModal(false)}
              // full="vertical"
              >
                <Box fill="vertical" overflow="auto">
                  <EditMeeting
                    meetingId={editMeetingId}
                    showModal={showEditModal}
                    setShowModal={setShowEditModal}
                  />
                </Box>
              </Layer>
            )}
            {showUploadFileModal && uploadFileToMeetingId && (
              <Layer
                onEsc={() => setShowUploadFileModal(false)}
                onClickOutside={() => setShowUploadFileModal(false)}
              // full="vertical"
              >
                <Box fill="vertical" overflow="auto">
                  <AddFileToMeeting
                    meetingId={uploadFileToMeetingId}
                    showModal={showUploadFileModal}
                    setShowModal={setShowUploadFileModal}
                  />
                </Box>
              </Layer>
            )}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};
