import { Box } from "grommet";
import { Edit, Trash, Upload } from "grommet-icons";
import "moment-timezone";
import React, { useContext } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import {
  useArticlesQuery,
  useDeleteArticleMutation
} from "../generated/graphql";
import { truncateString } from "../utils/truncateString";
import { ShadowBox } from "./layout/ShadowBox";
interface Props {
  limit?: number;
  grid?: boolean;

  setShowEditModal?: (b: boolean) => void;
  setEditArticleId?: (s: string) => void;

  setShowUploadFileModal?: (b: boolean) => void;
  setUploadFileToArticleId?: (s: string) => void;
}

export const NewsListing: React.FC<Props> = ({
  limit,
  grid = false,
  setShowEditModal,
  setEditArticleId,
  setShowUploadFileModal,
  setUploadFileToArticleId
}) => {
  const { user } = useContext(UserContext);
  let isPrivileged = true;
  if (user && (user.isPrivileged || user.isAdmin)) {
    isPrivileged = false;
  }
  // console.log(isPrivileged);
  const [deleteArticle] = useDeleteArticleMutation({
    refetchQueries: ["Articles"]
  });
  const { data } = useArticlesQuery({
    variables: { limit, isPrivileged }
  });
  let articles: JSX.Element[] | null = null;
  if (data && data.articles.length > 0) {
    console.log(data.articles);
    articles = data!.articles.map((article, idx) => {
      // const hemlContent = atob(article.content);
      // const strippedHtmlContent = hemlContent.replace(/<[^>]*>?/gm, "");
      // console.log(strippedHtmlContent);
      return (
        <ShadowBox key={`${idx}`} grid={grid}>
          <Box direction="row" justify="between">
            <span>
              <Moment style={{ fontSize: 14 }} format="YYYY-MM-DD">
                {article.date}
              </Moment>
              {article.isPrivileged && (
                <span
                  style={{
                    fontSize: 13,
                    fontVariant: "italic"
                  }}
                >
                  {" "}
                  - Priviligerad
                </span>
              )}
            </span>
            {user && user.isAdmin && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {typeof setUploadFileToArticleId !== "undefined" &&
                  typeof setShowUploadFileModal !== "undefined" && (
                    <div
                      style={{ marginRight: "8px" }}
                      onClick={() => {
                        setUploadFileToArticleId(article.id);
                        setShowUploadFileModal(true);
                      }}
                    >
                      <Upload color="rgba(0,0,0,0.3)" />
                    </div>
                  )}
                {typeof setEditArticleId !== "undefined" &&
                  typeof setShowEditModal !== "undefined" && (
                    <div
                      style={{ marginRight: "8px" }}
                      onClick={() => {
                        setEditArticleId(article.id);
                        setShowEditModal(true);
                      }}
                    >
                      <Edit color="rgba(0,0,0,0.3)" />
                    </div>
                  )}
                <div
                  onClick={() => {
                    if (
                      window.confirm(
                        `Vill du verkligen ta bort ${article.title}?`
                      )
                    ) {
                      deleteArticle({ variables: { id: article.id } });
                    }
                  }}
                >
                  <Trash color="rgba(0,0,0,0.3)" />
                </div>
              </div>
            )}
          </Box>
          <h3
            style={{
              margin: 0
            }}
          >
            {article.title}
          </h3>
          <div
            style={{ marginBottom: "20px" }}
            dangerouslySetInnerHTML={{
              __html: truncateString(
                article.content.replace(/\\n/g, "").replace(/<[^>]*>?/gm, ""),
                150,
                "..."
              )
            }}
          />
          <div
            style={{
              alignSelf: "flex-end",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Link to={`/news/${article.id}`}>Läs mer</Link>
            {article.company && (
              <div>
                Relaterat:{" "}
                <Link to={`/b/${article.company.id}`}>
                  {article.company.name}
                </Link>
              </div>
            )}
          </div>
        </ShadowBox>
      );
    });
  }
  // console.log(articles);

  if (data && data.articles.length < 1) {
    return <div>Inga artiklar hittades</div>;
  }
  return <>{articles}</>;
};
