import React from "react";
import { Company } from "../components/Company";
import { c } from "../config";
interface Props {}

export const ExpanderaPage: React.FC<Props> = () => {
  return (
    <>
      <Company companyId={c.REACT_APP_EXPANDERA_ID} />
    </>
  );
};
