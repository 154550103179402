import { Box, Button, FormField, TextInput } from "grommet";
import React, { useContext, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { MeDocument, MeQuery, useLoginMutation } from "../generated/graphql";

export const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const { setAccessToken, setUser } = useContext(UserContext);
  const [login] = useLoginMutation();

  return (
    <Box
      fill
      justify="center"
      align="center"
      style={{
        backgroundImage: "url('/background.jpg'",
        backgroundSize: "cover"
      }}
    >
      <div
        style={{
          padding: 40,
          margin: 10,
          backgroundColor: "#fff",
          borderRadius: 10
        }}
      >
        <form
          onSubmit={async e => {
            e.preventDefault();

            let response;
            try {
              response = await login({
                variables: {
                  email,
                  password
                },
                update: (store, { data }) => {
                  if (!data) {
                    return null;
                  }
                  store.writeQuery<MeQuery>({
                    query: MeDocument,
                    data: {
                      me: data.login.user
                    }
                  });
                }
              });
              console.log(response);
              if (
                response.data.login.accessToken &&
                typeof setAccessToken !== "undefined"
              ) {
                if (response && response.data) {
                  console.log("There was a token in the response!");

                  setAccessToken(response.data.login!.accessToken);
                }
                if (
                  typeof setUser !== "undefined" &&
                  response.data.login.user
                ) {
                  console.log("updating context user");
                  setUser(response.data.login.user);
                }
              }
            } catch (err) {
              setLoginError(
                "Inloggningen misslyckades, kontrollera epost och lösenord."
              );
              console.log("Ingen inloggning!");
            }
          }}
        >
          <h3>Välkommen till Expandera i Norr - Internt</h3>
          <p>Logga in med de uppgifter du fått på mejl eller sms.</p>
          <FormField>
            <TextInput
              placeholder="E-postadress"
              value={email}
              onChange={({ target }) => {
                setEmail(target.value);
              }}
            />
          </FormField>
          <FormField>
            <TextInput
              value={password}
              type="password"
              placeholder="Lösenord"
              onChange={({ target }) => {
                setPassword(target.value);
              }}
            />
          </FormField>
          {loginError && (
            <div style={{ color: "red" }}>
              <p>{loginError}</p>
            </div>
          )}
          <div>
            {/* // TODO Translate */}
            <Button label="Logga in" type="submit" />
          </div>
          <div
            style={{
              marginTop: 10,
              textAlign: "right"
            }}
          >
            <Link to="/forgot">Glömt lösenordet?</Link>
          </div>
        </form>
      </div>
    </Box>
  );
};
