import { Box, Button, Form, FormField, TextInput } from "grommet";
import { Save } from "grommet-icons";
import React, { useState } from "react";
import { useAddLinkToCompanyMutation } from "../generated/graphql";

interface Props {
  showModal: boolean;
  setShowModal(s: boolean): void;
  companyId: string;
}

export const CreateLink: React.FC<Props> = ({ setShowModal, companyId }) => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  const [addLinkToCompnay] = useAddLinkToCompanyMutation({
    refetchQueries: ["Company"]
  });
  //   const [createFolder] = useCreateFolderMutation({
  //   refetchQueries: [`Company`, "Folder"]
  // });
  // const [createArticle] = useCreateArticleMutation({
  //   refetchQueries: ["articles"]
  // });

  return (
    <Box pad="large">
      <h2>Lägg till länk</h2>
      <Form>
        <FormField label="Namn">
          <TextInput
            placeholder="skriv här"
            onChange={({ target }) => {
              setName(target.value);
            }}
          />
        </FormField>

        <FormField label="Url">
          <TextInput
            placeholder="skriv här, inkl http://"
            onChange={({ target }) => {
              setUrl(target.value);
            }}
          />
        </FormField>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            primary
            icon={<Save />}
            label="Spara"
            onClick={async () => {
              try {
                addLinkToCompnay({
                  variables: {
                    companyId,
                    name,
                    url
                  }
                });
                // createFolder({
                //   variables: {
                //     companyId: companyId,
                //     parentId: parentId || null,
                //     name,
                //     isPrivileged: privilegedChecked
                //   }
                // });
                // createArticle({
                //   variables: {
                //     title: title,
                //     content: htmlContent,
                //     sms: smsChecked,
                //     mail: mailChecked,
                //     isPrivileged: privilegedChecked
                //   }
                // });
                setShowModal(false);
              } catch (error) {
                console.log(error);
              }
            }}
          />
          <Button label="Stäng" onClick={() => setShowModal(false)} />
        </div>
      </Form>
    </Box>
  );
};
