import { Layer } from "grommet";
import { AddCircle, Trash } from "grommet-icons";
import React, { useContext, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { useDeleteLinkFromCompanyMutation } from "../generated/graphql";
import { compareByName } from "../utils/compare";
import { CreateLink } from "./CreateLink";
interface Props {
  companyId: string;
  links?: any[] | null;
}

export const CompanyLinks: React.FC<Props> = ({ companyId, links = [] }) => {
  const [showLinkModal, setShowLinkModal] = useState(false);
  const { user } = useContext(UserContext);

  const [deleteLinkFromCompany] = useDeleteLinkFromCompanyMutation({
    refetchQueries: ["Company"]
  });
  return (
    <div style={{ marginTop: 40 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <h3>Relaterade länkar</h3>
        {user && user.isAdmin && (
          <div onClick={() => setShowLinkModal(true)}>
            <AddCircle color="#398722" />
          </div>
        )}
      </div>
      {links && links.length > 0 && (
        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
          {links.length &&
            links.sort(compareByName).map((link, idx) => (
              <li>
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "space-between"
                  }}
                >
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.name}
                  </a>

                  {user && user.isAdmin && (
                    <div
                      // style={{ float: "right" }}
                      onClick={() => {
                        if (
                          window.confirm(
                            `Vill du verkligen ta bort ${link.name}?`
                          )
                        ) {
                          deleteLinkFromCompany({
                            variables: { linkId: link.id }
                          });
                        }
                      }}
                    >
                      <Trash color="rgba(0,0,0,0.3)" />
                    </div>
                  )}
                </div>
              </li>
            ))}
        </ul>
      )}

      {showLinkModal && (
        <Layer
          onEsc={() => setShowLinkModal(false)}
          onClickOutside={() => setShowLinkModal(false)}
        >
          <CreateLink
            showModal={showLinkModal}
            setShowModal={setShowLinkModal}
            companyId={companyId}
          />
        </Layer>
      )}
    </div>
  );
};
