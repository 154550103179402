import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { useRegisterMutation, useUsersQuery } from "../generated/graphql";


export const Register: React.FC<RouteComponentProps> = ({ history }) => {
const { data } = useUsersQuery({ fetchPolicy: "network-only" });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPrivileged, setIsPrivileged] = useState(false);

  const [register] = useRegisterMutation();


    if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <>
    <form
      onSubmit={async e => {
        e.preventDefault();
        console.log("form submitted");

        let response;
        try {
          response = await register({
            variables: {
              email,
              password,
              firstName,
              lastName,
              mobile,
              isAdmin,
              isPrivileged
            }
          });
          console.log(response);
        } catch (err) {
          console.log("OH FUCK NO!");
        }

        history.push("/");
      }}
    >
      <div>
        <input
          value={firstName}
          // TODO: Translate
          placeholder="firstName"
          onChange={e => {
            setFirstName(e.target.value);
          }}
        />
      </div>
      <div>
        <input
          value={lastName}
          // TODO: Translate
          placeholder="lastName"
          onChange={e => {
            setLastName(e.target.value);
          }}
        />
      </div>
      <div>
        <input
          value={email}
          // TODO: Translate
          placeholder="email"
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div>
        <input
          type="password"
          value={password}
          // TODO: Translate
          placeholder="password"
          onChange={e => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <div>
        <input
          value={mobile}
          // TODO: Translate
          placeholder="mobile"
          onChange={e => {
            setMobile(e.target.value);
          }}
        />
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isAdmin}
            // TODO: Translate
            onChange={() => {
              setIsAdmin(!isAdmin);
            }}
          />
          {/* //TODO translate */}
          Is Admin
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isPrivileged}
            // TODO: Translate
            onChange={() => {
              setIsPrivileged(!isPrivileged);
            }}
          />
          {/* //TODO translate */}
          Is Privileged
        </label>
      </div>
      <div>
        {/* // TODO Translate */}
        <button type="submit">Register</button>
      </div>
    </form>

    <h4>Använare</h4>
      <ul>
        {data.users.map(u => {
          return (
            <li key={u.id}>
              {u.firstName} {u.lastName} - {u.email} / {u.mobile}
            </li>
          );
        })}
      </ul>
      </>
  );
};
