import React, { useState } from "react";
import { User } from "../generated/graphql";

type UserContextProps = {
  user?: User;
  setUser: (User) => void;
  appLoading: boolean;
  setAppLoading: (b: boolean) => void;

  accessToken: string;
  setAccessToken: (s: string) => void;
};

export const UserContext = React.createContext<Partial<UserContextProps>>({
  accessToken: localStorage.getItem("accessToken") || ""
});

const UserProvider = ({ children }) => {
  // context state
  const [appLoading, setAppLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [user, setUser] = useState();

  // context initialization
  // useEffect(() => {
  //   if (accessToken) {
  //     console.log("APP HAS AT: ", accessToken);
  //   } else {
  //     console.log("APP HAS NO AT!");
  //   }
  // }, [accessToken]);
  return (
    <UserContext.Provider
      value={{
        appLoading,
        setAppLoading,
        accessToken,
        setAccessToken,
        user,
        setUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
