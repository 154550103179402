import { ResponsiveContext } from "grommet";
import React from "react";
import styled from "styled-components";
interface Props {}

export const RightColumn: React.FC<Props> = ({ children }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => {
        if (size === "small") {
          return (
            <RightColumnWrapperMobile>{children}</RightColumnWrapperMobile>
          );
        }
        return <RightColumnWrapper>{children}</RightColumnWrapper>;
      }}
    </ResponsiveContext.Consumer>
  );
};

const RightColumnWrapperMobile = styled.div`
  margin-top: 20px;
`;
const RightColumnWrapper = styled.div`
  margin-left: 10px;
  flex: 1;
  flex-grow: 1;
`;
